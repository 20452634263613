export default function Priority({ setSortOrder }: any) {
  const priorityNumbers = () => {
    const priorArray = []
    for (let i = 0; i <= 10; i++) {
      priorArray.push(<option key={i}>{i}</option>)
    }
    return priorArray
  }
  return (
    <div>
      <label htmlFor='priority' className='block text-sm font-medium text-gray-700'>
        Priority
      </label>
      <select
        id='priority'
        name='priority'
        className='mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
        onChange={(e) => setSortOrder(e.target.value)}
      >
        {priorityNumbers()}
      </select>
    </div>
  )
}
