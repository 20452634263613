import {
  ArrowLeftCircleIcon,
  BanknotesIcon,
  DevicePhoneMobileIcon,
  MapIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import formatRelative from 'date-fns/formatRelative'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { SpinnerRoundFilled } from 'spinners-react'
import { ObjectType } from 'typescript'
import { AppDispatch, RootState } from '../../../app/store'
import { statusStyles } from '../../../app/utils/data-mock'
import { classNames } from '../../../app/utils/helper'
import { RequestStatus } from '../../../app/utils/interfaces'
import { selectAuth } from '../../auth/authSlice'
import { resetById } from '../provider_managment/provider_list/providerListSlice'
import { getOrderById, IOrder, orderStatus } from './orderSlice'
export default function OrderDetails() {
  const params = useParams()
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const ordersState = useSelector((state: RootState) => state.orders)
  const authState = useSelector(selectAuth)

  function handleBackButton() {
    navigate(-1)
    dispatch(resetById())
  }
  useEffect(() => {
    if (authState.status == RequestStatus.success) {
      dispatch(getOrderById({ token: authState.user.token, id: params.id }))
    }
  }, [authState.status])

  let content
  if (ordersState.requestById.status === RequestStatus.loading) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  } else if (ordersState.requestById.status === RequestStatus.success) {
    const order: IOrder = ordersState.requestById.data!
    const { phone: providerPhone, fullName: providerFullName } = order.providers
    const { fullName, phone } = order.users
    const {
      totalAmount,
      createdAt,
      statusOrder,
      workTime,
      startLocation,
      endLocation,
      quantity,
      orderKey,
      detailsOrder,
    } = order
    content = (
      <div className='overflow-hidden bg-white shadow sm:rounded-lg'>
        <div className='flex items-start gap-5 px-4 py-5 sm:px-6'>
          <span>
            <ArrowLeftCircleIcon
              className='h-8 w-8 text-gray-500 hover:text-gray-600'
              onClick={handleBackButton}
            />
          </span>
          <div>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              Order <span className='cursor-pointer text-indigo-600'>#{orderKey}</span>{' '}
              <span
                className={classNames(
                  statusStyles[orderStatus[statusOrder.toString() as keyof ObjectType]],
                  'inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium capitalize',
                )}
              >
                {orderStatus[statusOrder.toString() as keyof ObjectType]}
              </span>
            </h3>
            <p className='mt-1 max-w-2xl text-sm text-gray-500'>
              {formatRelative(new Date(createdAt), new Date())}
            </p>
          </div>
        </div>
        <div className='border-t border-gray-200 px-4 py-5 sm:p-0'>
          <dl className='sm:divide-y sm:divide-gray-200'>
            <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Provider Information</dt>
              <ul
                role='list'
                className='divide-y divide-gray-200 rounded-md border border-gray-200'
              >
                <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
                  <div className='flex w-0 flex-1 items-center'>
                    <UserIcon className='h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
                    <span className='ml-2 w-0 flex-1 truncate'>{providerFullName}</span>
                  </div>
                </li>
                <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
                  <div className='flex w-0 flex-1 items-center'>
                    <DevicePhoneMobileIcon
                      className='h-5 w-5 flex-shrink-0 text-gray-400'
                      aria-hidden='true'
                    />
                    <span className='ml-2 w-0 flex-1 truncate'>{providerPhone}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Customer Information</dt>
              <ul
                role='list'
                className='divide-y divide-gray-200 rounded-md border border-gray-200'
              >
                <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
                  <div className='flex w-0 flex-1 items-center'>
                    <UserIcon className='h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
                    <span className='ml-2 w-0 flex-1 truncate'>{fullName}</span>
                  </div>
                </li>
                <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
                  <div className='flex w-0 flex-1 items-center'>
                    <DevicePhoneMobileIcon
                      className='h-5 w-5 flex-shrink-0 text-gray-400'
                      aria-hidden='true'
                    />
                    <span className='ml-2 w-0 flex-1 truncate'>{phone}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Order Details</dt>
              <ul
                role='list'
                className='divide-y divide-gray-200 rounded-md border border-gray-200'
              >
                <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
                  <div className='flex w-0 flex-1 items-center'>
                    start location:{' '}
                    <span className='ml-2 w-0 flex-1 truncate'>{startLocation}</span>
                  </div>
                </li>
                <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
                  <div className='flex w-0 flex-1 items-center'>
                    end location: <span className='ml-2 w-0 flex-1 truncate'>{endLocation}</span>
                  </div>
                </li>
                <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
                  <div className='flex w-0 flex-1 items-center'>
                    <span className=' w-0 flex-1 truncate'>category: {order?.category?.title}</span>
                  </div>
                </li>
                <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
                  <div className='flex w-0 flex-1 items-center'>
                    <span className=' w-0 flex-1 truncate'>
                      sub category: {order?.subCategory?.title}
                    </span>
                  </div>
                </li>
                <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
                  <div className='flex w-0 flex-1 items-center'>
                    <span className=' w-0 flex-1 truncate'>quantity: {quantity}</span>
                  </div>
                </li>
                <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
                  <div className='flex w-0 flex-1 items-center'>
                    <span className=' w-0 flex-1 truncate'>details: {detailsOrder}</span>
                  </div>
                </li>
              </ul>
            </div>
          </dl>
        </div>
      </div>
    )
  } else if (ordersState.requestById.status === RequestStatus.error) {
    content = <div>{ordersState.requestById.error}</div>
  }
  return authState.status == RequestStatus.success ? (
    <div className='h-screen bg-gray-100'>
      <div className='mx-auto max-w-6xl space-y-6 px-4 pt-8 '>{content}</div>
    </div>
  ) : (
    <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
      <span className='visually-hidden'>
        <SpinnerRoundFilled size={100} />
      </span>
    </div>
  )
}
