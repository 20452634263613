import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { SpinnerRoundFilled } from 'spinners-react'
import { AppDispatch, RootState } from '../../../../app/store'
import { RequestStatus } from '../../../../app/utils/interfaces'
import { selectAuth } from '../../../auth/authSlice'
import Modal from '../../../components/Modal'
import CustomNotification from '../../../components/notification'
import { createDocument, getDocuments, setModalId, updateCategoryStatus } from './categorySlice'
import DocumentsList from './DocumentsList'

interface IFormInput {
  title: string
}

export default function CategoryDocuments() {
  const [show, setShow] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>()
  const dispatch: AppDispatch = useDispatch()
  const authState = useSelector(selectAuth)
  const categoryState = useSelector((state: RootState) => state.category)
  const [open, setOpen] = useState(false)
  const [openNotify, setOpenNotify] = useState(false)
  const onSubmit = (data: IFormInput) => {
    if (data) {
      dispatch(
        createDocument({
          token: authState.user.token,

          title: data.title,
        }),
      )
    }
  }

  useEffect(() => {
    if (authState.status == RequestStatus.success) {
      dispatch(getDocuments(authState.user.token))
    }
  }, [authState.status, categoryState.create.status])

  let content
  if (categoryState.documents.status === RequestStatus.loading) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  } else if (categoryState.documents.status === RequestStatus.success) {
    content = (
      <div>
        <Modal
          open={open}
          setOpen={setOpen}
          title='Operation confirmation'
          desc='By changing status this will affect the user app'
          onConfirm={async () => {
            // dispatch(
            //   updateCategoryStatus({
            //     token: authState.user.token,
            //     enabled: !categoryState.modalData?.enabled,
            //     id: categoryState.modalData?.id,
            //   }),
            // )
            // setTimeout(() => {
            //   setOpenNotify(true)
            // }, 1000)
          }}
        />
        <CustomNotification
          message='updated category successfully'
          show={openNotify}
          setShow={setOpenNotify}
        />
        <DocumentsList documents={categoryState.documents.data} onStatusClick={onStatusClick} />
      </div>
    )
  } else if (categoryState.status === RequestStatus.error) {
    content = <div>{categoryState.error}</div>
  }
  return (
    <div className='bg-gray-50'>
      {categoryState.create.status === RequestStatus.success && (
        <CustomNotification message='Category created successfully' show={show} setShow={setShow} />
      )}
      <div className='mx-auto max-w-6xl space-y-6 px-4 pt-8'>
        <div className='bg-white px-4 py-5 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg sm:p-6'>
          <div className=' flex flex-row gap-10'>
            <div className='w-1/3 space-y-4'>
              <h3 className='text-xl font-medium leading-6 text-gray-900'>Add Document</h3>
              <div className=''>
                <label
                  className={`block text-sm font-medium text-gray-700 ${
                    !errors.title?.message ? 'text-gray-700' : 'text-red-600'
                  }`}
                >
                  Title {errors.title?.message}
                </label>
                <input
                  type='text'
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                    !errors.title?.message
                      ? 'border focus:border-indigo-500 focus:ring-indigo-500'
                      : 'border-red-500 focus:border-red-500 focus:ring-red-500 '
                  }`}
                  {...register('title', {
                    required: {
                      value: true,
                      message: 'is required',
                    },
                  })}
                />
              </div>
              <button
                type='button'
                onClick={handleSubmit(onSubmit)}
                className='inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              >
                Submit
              </button>
            </div>
            <div className='w-2/3'>
              <h3 className='text-xl font-medium leading-6 text-gray-900'>Documents List</h3>
              <div>{content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  async function onStatusClick(id: string, enabled: boolean) {
    await dispatch(setModalId({ id: id, enabled: enabled }))
    setOpen(true)
  }
}
