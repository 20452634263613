import { Fragment, useEffect, useState } from 'react'
import Header from '../../../components/Header'
import Area from '../../../components/Area'
import CustomTable from '../../../components/CustomTable'
import Tabs from '../../../components/Tabs'
import RequestsTableBody from './RequestsTableBody'
import { requestsTabs, requestsTableHeader } from '../../../../app/utils/data-mock'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../app/store'
import { selectAuth } from '../../../auth/authSlice'
import { SpinnerRoundFilled } from 'spinners-react'
import { ITabs, RequestStatus } from '../../../../app/utils/interfaces'
import {
  getProvidersRequests,
  getRejectedProvidersRequests,
  resetUserById,
  sendUpdateMessage,
  setModalId,
  setTabGlobal,
  updateProviderRequest,
} from './providerRequestsSlice'
import CustomNotification from '../../../components/notification'
import Modal from '../../../components/Modal'
import ReasonMessage from './ReasonMessage'
const imgUrl = 'https://api.mueadaat.com/'
export default function ProviderRequests() {
  const dispatch: AppDispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [openNotify, setOpenNotify] = useState(false)
  const requests = useSelector((state: RootState) => state.requests)
  const authState = useSelector(selectAuth)
  const [tab, setTab] = useState(requests.tab)
  const [reasonMessage, setReasonMessage] = useState(false)
  function generateTabs(requestsLength: number, deniedLength: number): ITabs[] {
    return [
      { name: 'Requests', count: requestsLength },
      { name: 'Denied', count: deniedLength },
    ]
  }
  useEffect(() => {
    window.onpopstate = (e) => {
      dispatch(resetUserById())
    }
    // FileSaver.saveAs('https://httpbin.org/image', 'image.jpg')
    // FileSaver.saveAs(`${imgUrl}public/users/63960e96a5d85a77d7eae838/requestProviders/1671545713384.jpg`, 'image.jpg')
    if (authState.status == RequestStatus.success) {
      dispatch(getProvidersRequests(authState.user.token))
      dispatch(getRejectedProvidersRequests(authState.user.token))
    }
  }, [authState.status])

  let content
  if (
    requests.providersRequests.status === RequestStatus.loading &&
    requests.rejectedProviders.status === RequestStatus.loading
  ) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  }
  if (
    requests.providersRequests.status === RequestStatus.success &&
    requests.rejectedProviders.status === RequestStatus.success
  ) {
    if (tab === 'Requests') {
      if (requests.providersRequests.length === 0) {
        content = (
          <div className='flex w-full justify-center p-4'>
            <h1>There is no data to show</h1>
          </div>
        )
      } else {
        content = (
          <RequestsTableBody
            people={requests.providersRequests.data}
            onAcceptClick={onAcceptClick}
            onRejectClick={onRejectClick}
            onUpdateClick={onUpdateClick}
          />
        )
      }
    } else {
      if (requests.rejectedProviders.length === 0) {
        content = (
          <div className='flex w-full justify-center p-4'>
            <h1>There is no data to show</h1>
          </div>
        )
      } else {
        content = (
          <RequestsTableBody
            people={requests.rejectedProviders.data}
            onAcceptClick={onAcceptClick}
          />
        )
      }
    }
  } else if (
    requests.providersRequests.status === RequestStatus.error &&
    requests.rejectedProviders.status === RequestStatus.error
  ) {
    content = <div>{requests.providersRequests.error}</div>
  }
  return authState.status === RequestStatus.success ? (
    <div className=' w-full bg-gray-50'>
      <Modal
        open={open}
        setOpen={setOpen}
        title='Operation confirmation'
        desc='By accepting provider the user can {provider_roles}'
        onConfirm={async () => {
          await dispatch(
            updateProviderRequest({
              token: authState.user.token,
              id: requests.modalData?.modalId,
              status: requests.modalData?.status,
            }),
          )
          setOpenNotify(true)
        }}
      />
      <ReasonMessage
        open={reasonMessage}
        setOpen={setReasonMessage}
        title='Send update message'
        onConfirm={async (message: string) => {
          await dispatch(
            sendUpdateMessage({
              token: authState.user.token,
              id: requests.modalData?.modalId,
              message: message,
            }),
          )
          setOpenNotify(true)
        }}
      />
      <CustomNotification
        message='updated provider successfully'
        show={openNotify}
        setShow={setOpenNotify}
      />
      <div className='mx-auto  max-w-6xl px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-col pt-8'>
          <Header text='Providers Requests' />
          <Tabs
            currentTab={tab}
            setTab={(val) => {
              dispatch(setTabGlobal(val))
              setTab(val)
            }}
            tabs={generateTabs(
              requests.providersRequests.length,
              requests.rejectedProviders.length,
            )}
          />
          <CustomTable tableHeaders={requestsTableHeader}>{content}</CustomTable>
        </div>
      </div>
    </div>
  ) : (
    <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
      <span className='visually-hidden'>
        <SpinnerRoundFilled size={100} />
      </span>
    </div>
  )
  async function onAcceptClick(id: string) {
    await dispatch(setModalId({ modalId: id, actionType: 'accept', status: 1 }))
    setOpen(true)
  }
  async function onRejectClick(id: string) {
    await dispatch(setModalId({ modalId: id, actionType: 'deny', status: -1 }))
    setOpen(true)
  }

  async function onUpdateClick(id: string) {
    await dispatch(setModalId({ modalId: id, token: authState.user.token }))
    setReasonMessage(true)
  }
}
