import { ArrowLeftCircleIcon, DevicePhoneMobileIcon, UserIcon } from '@heroicons/react/24/outline'
import { formatDistance, formatRelative, subDays } from 'date-fns'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { SpinnerRoundFilled } from 'spinners-react'
import { AppDispatch, RootState } from '../../../../app/store'
import { RequestStatus } from '../../../../app/utils/interfaces'
import { selectAuth } from '../../../auth/authSlice'
import {
  getProviderRequestById,
  IProviderDocument,
  IRequestById,
  resetUserById,
} from './providerRequestsSlice'

const imgUrl = 'https://api.mueadaat.com/'

export default function RequestDetails() {
  const params = useParams()
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const request = useSelector((state: RootState) => state.requests)
  const authState = useSelector(selectAuth)

  function handleBackButton() {
    navigate(-1)
    dispatch(resetUserById())
  }
  useEffect(() => {
    if (authState.status == RequestStatus.success) {
      dispatch(getProviderRequestById({ token: authState.user.token, id: params.id }))
    }
  }, [authState.status])

  let content
  if (request.requestById.status === RequestStatus.loading) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  } else if (request.requestById.status === RequestStatus.success) {
    const data: IRequestById = request.requestById.data!
    // content = <ProviderListTableBody people={providersState.providers} />
    content = (
      <div className='mx-auto max-w-6xl space-y-6 px-4 pt-8 '>
        <div className='overflow-hidden bg-white shadow sm:rounded-lg'>
          <div className='flex items-start gap-5 px-4 py-5 sm:px-6'>
            <span>
              <ArrowLeftCircleIcon
                className='h-8 w-8 text-gray-500 hover:text-gray-600'
                onClick={handleBackButton}
              />
            </span>
            <div>
              <h3 className=' text-lg font-medium leading-6 text-gray-900'>Provider Request</h3>
              <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                {formatRelative(new Date(data.dataRequest.createdAt), new Date())}
              </p>
            </div>
          </div>
          <ProviderInformation username={data.user.fullName!} phone={data.user.phone} />
          <ProviderImages documents={request.requestById.data?.dataRequest.providerDocument} />
        </div>
      </div>
    )
  } else if (request.requestById.status === RequestStatus.error) {
    content = <div>{request.requestById.error}</div>
  }
  return <div className='h-screen bg-gray-100'>{content}</div>
}

function ProviderInformation({ username, phone }: { username: string; phone: string }) {
  return (
    <div className='border-t border-gray-200 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
      <dt className='text-sm font-medium text-gray-500'> Provider Information</dt>
      <ul role='list' className='divide-y divide-gray-200 rounded-md border border-gray-200'>
        <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
          <div className='flex w-0 flex-1 items-center'>
            <UserIcon className='h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
            <span className='ml-2 w-0 flex-1 truncate'>{username}</span>
          </div>
        </li>
        <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
          <div className='flex w-0 flex-1 items-center'>
            <DevicePhoneMobileIcon
              className='h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            />
            <span className='ml-2 w-0 flex-1 truncate'>{phone}</span>
          </div>
        </li>
      </ul>
    </div>
  )
}

function ProviderImages({ documents }: { documents?: IProviderDocument[] }) {
  return (
    <div className='border-t border-gray-200 px-4 py-5 sm:p-0'>
      <dl className='sm:divide-y sm:divide-gray-200'>
        <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>Provider Documents</dt>
          <div className='flex gap-3'>
            {documents?.map((doc, index) => (
              <div className='w-40 rounded-md border border-gray-100 p-2'>
                <p dir='rtl' className='mb-3'>
                  {doc.title}
                </p>
                <div className='' key={index}>
                  <img src={`${imgUrl}${doc.path}`} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </dl>
    </div>
  )
}
