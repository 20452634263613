import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type PaginationState = {
  currentPage: number
  pageSize: number
}

type TablePaginationState = {
  [tableName: string]: PaginationState
}

type PaginationStore = {
  tables: TablePaginationState
  getPaginationState: (tableName: string) => PaginationState
  setPaginationState: (tableName: string, paginationState: PaginationState) => void
  resetPaginationState: (tableName: string) => void
}

export const DEFAULT_PAGINATION_STATE = {
  currentPage: 0,
  pageSize: 10,
}

const usePaginationStore = create(
  devtools<PaginationStore>(
    (set, get) => ({
      tables: {},
      getPaginationState: (tableName: string) => {
        const tables: TablePaginationState = get().tables
        const table = tables[tableName]

        if (table) {
          return table
        } else {
          set((state) => ({
            tables: {
              ...state.tables,
              [tableName]: DEFAULT_PAGINATION_STATE,
            },
          }))
          return DEFAULT_PAGINATION_STATE
        }
      },
      setPaginationState: (tableName: string, paginationState: PaginationState) => {
        set((state) => ({
          tables: {
            ...state.tables,
            [tableName]: paginationState,
          },
        }))
      },
      resetPaginationState: (tableName: string) => {
        set((state) => ({
          tables: {
            ...state.tables,
            [tableName]: DEFAULT_PAGINATION_STATE,
          },
        }))
      },
    }),
    { store: 'pagination-store' },
  ),
)

export default usePaginationStore
