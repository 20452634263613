import Select from 'react-select'

export default function SubOptions({ selectedOption, setSelectedOption, options }: any) {
  return (
    <div className='App'>
      <Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
        isSearchable={false}
      />
    </div>
  )
}
