export default function IconBtn({ text, Icon }: { text: string; Icon: JSX.Element }) {
  return (
    <button
      type='button'
      className='inline-flex items-center rounded-md border border-transparent bg-indigo-50 px-4 py-2 text-sm font-medium capitalize text-indigo-700 shadow-sm hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
    >
      {text}
      {Icon}
    </button>
  )
}
