import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'
import { IPeople } from '../../../../app/utils/interfaces'
import { classNames } from '../../../../app/utils/helper'
import ErrorBtn from '../../../components/ErrorBtn'
import IconBtn from '../../../components/IconBtn'
import SuccessBtn from '../../../components/SuccessBtn'
import { IProviderRequest } from './providerRequestsSlice'
import { Link } from 'react-router-dom'

interface IProvidersListProps {
  people: IProviderRequest[] | undefined
  onAcceptClick: any
  onRejectClick?: any
  onUpdateClick?: any
}
export default function RequestsTableBody({
  people,
  onAcceptClick,
  onRejectClick,
  onUpdateClick,
}: IProvidersListProps) {
  let tmp = people?.map((person, personIdx) => (
    <tr
      key={personIdx}
      className={classNames(personIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
    >
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>{personIdx + 1}</td>
      <Link to={`/requests/${person._id}`}>
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
          {person.fullName}
        </td>
      </Link>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>{person.phone}</td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <IconBtn
          text='download'
          Icon={<ArrowDownOnSquareIcon className='ml-2 -mr-1 h-5 w-5' aria-hidden='true' />}
        />
      </td>
      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
        <div className='flex flex-row gap-5'>
          <SuccessBtn text='accept' onClick={() => onAcceptClick(person._id)} />
          <button
            onClick={() => onUpdateClick(person._id)}
            className='rounded-md bg-indigo-50 px-4 py-2 capitalize text-indigo-700 shadow-sm hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2  '
          >
            send message
          </button>
          {onRejectClick !== undefined && (
            <ErrorBtn text='deny' onClick={() => onRejectClick(person._id)} />
          )}
        </div>
      </td>
    </tr>
  ))
  return <>{tmp}</>
}
