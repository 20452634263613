/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { useGlobalFilter, usePagination, useTable, useSortBy } from 'react-table'
import { ORDER_COLUMNS } from './columns/_columns'

type Props = {
  orders: any[]
}

const OrdersTable: React.FC<Props> = ({ orders }) => {
  const data = useMemo(() => orders, [orders])
  const columns = useMemo(() => ORDER_COLUMNS, [])

  const {
    headers,
    getTableBodyProps,
    getTableProps,
    page,
    prepareRow,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    state,
    pageCount,
    setGlobalFilter,
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageSize: 5,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const { globalFilter, pageIndex, pageSize } = state
  const defaultPageSizes = [5, 10, 15, 20]

  return (
    <div className='hidden sm:block'>
      <div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8'>
        <div className='mt-2 flex flex-col'>
          <div className='min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg'>
            <table className='min-w-full divide-y divide-gray-200' {...getTableProps()}>
              <thead>
                <tr className=''>
                  {headers.map((header, index) => (
                    <th
                      className='bg-gray-50 px-10 py-3 text-left text-sm font-semibold text-gray-900'
                      {...header.getHeaderProps(header.getSortByToggleProps())}
                    >
                      {header.render('Header')}
                      <span>{header.isSorted ? (header.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody {...getTableBodyProps()} className='divide-y divide-gray-200 bg-white'>
                {page.map((row) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, index) => (
                        <td className='px-4' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <nav
              className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6'
              aria-label='Pagination'
            >
              <div className='hidden sm:block'>
                <p className='text-sm text-gray-700'>
                  Showing <span className='font-medium'>1</span> to{' '}
                  <span className='font-medium'>{pageSize}</span> of{' '}
                  <span className='font-medium'>{pageCount}</span> results
                </p>
              </div>
              <div className='flex flex-1 justify-between sm:justify-end'>
                <button
                  onClick={previousPage}
                  className='relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'
                >
                  Previous
                </button>
                <button
                  onClick={nextPage}
                  className='relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'
                >
                  Next
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export { OrdersTable }
