import { api } from '../../../../../../api/axios_instance'

const updateServiceAddress = async (serviceId: string, address: { city: any; district: any }) => {
  const response = await api.put(`/services/update-address/${serviceId}`, {
    address: {
      city: address.city,
      district: address.district,
    },
  })
  return response.data
}

export { updateServiceAddress }
