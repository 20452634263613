import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '../../../api/axios_instance'
import { RequestStatus } from '../../../app/utils/interfaces'

interface IProviderListState {
  status: RequestStatus
  notifyMessage?: string
}

const initialState: IProviderListState = {
  status: RequestStatus.idle,
}

export const sendNotification = createAsyncThunk(
  'notification/sendNotification',
  async (payload: any) => {
    const response = await api.post(
      `/notification/send-topic`,
      {
        title: payload.title,
        body: payload.body,
        type: payload.type,
      },
      {
        headers: {
          authorization: payload.token,
        },
      },
    )

    return response.data
  },
)

export const notification = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(sendNotification.fulfilled, (state) => {
        state.status = RequestStatus.success
        state.notifyMessage = 'sended notfication successfully'
      })
      .addCase(sendNotification.rejected, (state, action) => {
        state.status = RequestStatus.error
        state.notifyMessage = action.error.message
      })
  },
})

export default notification.reducer
export const {} = notification.actions
