import { ITabs } from '../../app/utils/interfaces'
import { classNames } from '../../app/utils/helper'

export interface ITabsProps {
  currentTab: string
  setTab: React.Dispatch<React.SetStateAction<string>>
  tabs: ITabs[]
}
export default function Tabs({ currentTab, setTab, tabs }: ITabsProps) {
  return (
    <div className='my-4'>
      <div className='hidden sm:block'>
        <div className='border-b border-gray-200'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            {tabs.map((tab) => (
              <a
                key={tab.name}
                className={classNames(
                  tab.name === currentTab
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
                )}
                onClick={(e) => setTab(tab.name)}
              >
                {tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.name === currentTab
                        ? 'bg-indigo-100 text-indigo-600'
                        : 'bg-gray-100 text-gray-900',
                      'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block',
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
