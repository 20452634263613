import { BanknotesIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

const TextCell = ({ data }: any) => {
  return (
    <div className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
      <div className='flex'>
        <a href={data?._id} className='group inline-flex space-x-2 truncate text-sm'>
          <BanknotesIcon
            className='h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500'
            aria-hidden='true'
          />
          <Link to={`/order/${data?._id}`}>
            <p className='truncate text-gray-500 group-hover:text-gray-900'>
              Order to {data.providers?.fullName}
            </p>
          </Link>
        </a>
      </div>
    </div>
  )
}

export default TextCell
