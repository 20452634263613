import qs from 'qs'
import { useEffect } from 'react'
import { useQueryClient, useQuery } from 'react-query'
import { api } from '../../../api/axios_instance'
import usePaginationStore, { DEFAULT_PAGINATION_STATE } from '../../../app/store/tablesState'
import { SpinnerRoundFilled } from 'spinners-react'
import { OrdersTable } from './orders-table/OrdersTable'
import Header from '../../components/Header'

const TABLE_NAME = 'orders-list'
const OrdersPage = () => {
  const tablePaginationStore = usePaginationStore()
  const queryClient = useQueryClient()

  // if there is a stored state for the table in the store get it
  // else use default pagination data
  const { currentPage, pageSize } =
    tablePaginationStore.tables[TABLE_NAME] ?? DEFAULT_PAGINATION_STATE

  const params = qs.stringify({ page: currentPage, limit: pageSize })
  const ordersQuery = useQuery(['orders', 'list', params], async () => {
    const res = await api.get(`/order?${params}`)
    return res.data
  })

  useEffect(() => {
    const params = qs.stringify({ page: currentPage + 1, limit: pageSize })
    queryClient.prefetchQuery({
      queryKey: ['orders', 'list', params],
      queryFn: async () => {
        const res = await api.get<{ Provider: any[]; pageSize: number }>(`/order?${params}`)
        return res.data
      },
    })
  }, [currentPage, pageSize])

  if (ordersQuery.isLoading) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  }

  if (ordersQuery.isError) {
    const error = ordersQuery.error as Error

    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <h2>{error.message}</h2>
      </div>
    )
  }

  const setPageIndex = (pageIndex: number) => {
    tablePaginationStore.setPaginationState(TABLE_NAME, {
      currentPage: pageIndex,
      pageSize: pageSize,
    })
  }

  const setPageSize = (pageSize: number) => {
    tablePaginationStore.setPaginationState(TABLE_NAME, {
      currentPage,
      pageSize: pageSize,
    })
  }

  return (
    <div className='w-full bg-gray-50 pb-10'>
      <div className='mx-auto  max-w-6xl px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-col pt-8'>
          <Header text='All Orders' />
          <div className='mt-4'>
            <OrdersTable
              orders={ordersQuery.data?.order}
              queryPageIndex={currentPage}
              queryPageSize={pageSize}
              queryPageCount={ordersQuery.data?.pageSize ?? 0}
              setQueryPageIndex={setPageIndex}
              setQueryPageSize={setPageSize}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export { OrdersPage }
