import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../app/store'
import { classNames } from '../../../../app/utils/helper'
import { selectAuth } from '../../../auth/authSlice'
import ErrorBtn from '../../../components/ErrorBtn'
import ToggleWithIcon from '../../../components/ToggleWithIcon'
import { deleteDocumentById, ICategoryDocument } from './categorySlice'

interface IDocumentsListTableProps {
  documents: ICategoryDocument[]
  onStatusClick: any
}
export default function DocumentsListTable({ documents, onStatusClick }: IDocumentsListTableProps) {
  const dispatch: AppDispatch = useDispatch()
  const authState = useSelector(selectAuth)
  let tmp = documents.map((doc, index) => (
    <tr
      key={index}
      className={classNames(index === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
    >
      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
        {index + 1}
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>{doc.title}</td>
      <td className='relative whitespace-nowrap py-4 pl-3 pr-4  text-sm font-medium sm:pr-6'>
        <ErrorBtn
          text='delete'
          onClick={() => {
            dispatch(deleteDocumentById({ token: authState.user.token, id: doc._id }))
          }}
        />
      </td>
    </tr>
  ))
  return <>{tmp}</>
}
