import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { selectAuth } from '../../../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RequestStatus } from '../../../../app/utils/interfaces'
import { getCategoryById, updateCategory, uploadImage } from './categorySlice'
import { AppDispatch, RootState } from '../../../../app/store'
import { SpinnerRoundFilled } from 'spinners-react'
import { useForm } from 'react-hook-form'
import ImageUploader from '../../../components/ImageUploader'
import toast from 'react-hot-toast'

const API_URL = 'https://api.mueadaat.com'

interface IFormInput {
  title: string
  attachments: string
}
const EditCategory = () => {
  const { id } = useParams()
  const [image, setImage]: any = useState()
  const authState = useSelector(selectAuth)
  const dispatch: AppDispatch = useDispatch()
  const categoryState = useSelector((state: RootState) => state.category)
  let content: any

  const onSubmit = (data: IFormInput) => {
    if (Boolean(image)) {
      const form = new FormData()
      form.append('image', image)
      dispatch(uploadImage({ token: authState.user.token, data: form, id }))
    }
    // const form = new FormData()
    if (data) {
      dispatch(updateCategory({ token: authState.user.token, data, id }))
      toast.success('updated category successfully')
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<IFormInput>()

  const onDrop = useCallback((acceptedFiles: any) => {
    setImage(acceptedFiles[0])
  }, [])

  useEffect(() => {
    if (authState.status == RequestStatus.success) {
      dispatch(getCategoryById({ token: authState.user.token, id }))
    }
  }, [authState.status])

  useEffect(() => {
    if (categoryState.editCategory.status == RequestStatus.success) {
      const data = categoryState.editCategory.data
      setValue('title', data!.title)
    }
  }, [categoryState.editCategory.status])

  if (
    authState.status === RequestStatus.loading ||
    categoryState.editCategory.status === RequestStatus.loading
  ) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  }
  if (categoryState.editCategory.status === RequestStatus.success) {
    const data = categoryState.editCategory.data
    content = (
      <div className='bg-gray-50'>
        <div className='mx-auto max-w-6xl space-y-6 px-4 pt-8'>
          <div className='bg-white px-4 py-5 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg sm:p-6'>
            <h3 className='text-xl font-medium leading-6 text-gray-900'>Category Setup</h3>
            <div className='mt-6 flex flex-row gap-10'>
              <div className='w-1/3 space-y-4'>
                <div className=''>
                  <label
                    className={`block text-sm font-medium text-gray-700 ${
                      !errors.title?.message ? 'text-gray-700' : 'text-red-600'
                    }`}
                  >
                    Category Name {errors.title?.message}
                  </label>
                  <input
                    type='text'
                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                      !errors.title?.message
                        ? 'border focus:border-indigo-500 focus:ring-indigo-500'
                        : 'border-red-500 focus:border-red-500 focus:ring-red-500 '
                    }`}
                    {...register('title', {
                      required: {
                        value: true,
                        message: 'is required',
                      },
                    })}
                  />
                </div>
              </div>
              <div className='flex w-2/3 flex-col justify-between space-y-4 '>
                <div className=''>
                  <div>
                    <label
                      className={`block text-sm font-medium text-gray-700 ${
                        !errors.attachments?.message ? 'text-gray-700' : 'text-red-600'
                      }`}
                    >
                      Logo Image {errors.attachments?.message}
                    </label>
                    <div
                      className={`mt-1 rounded-md border-2 border-dashed   ${
                        !errors.attachments?.message ? 'border-gray-300' : 'border-red-500  '
                      }`}
                    >
                      <ImageUploader
                        onDrop={onDrop}
                        register={register}
                        defaultImage={`${API_URL}/${data?.image?.path}`}
                      />
                    </div>
                  </div>
                </div>
                <div className='  text-right '>
                  <button
                    type='button'
                    onClick={handleSubmit(onSubmit)}
                    className='inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {content}
      </div>
    )
  } else if (
    authState.status === RequestStatus.error ||
    categoryState.editCategory.status === RequestStatus.error
  ) {
    content = <div>{categoryState.error}</div>
  }
  return <div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8'>{content}</div>
}

export { EditCategory }
