import { RoutesEnum } from '../routes'

export interface ITabs {
  name: string
  count: number | string  
}
export enum RequestStatus {
  idle = 'idle',
  loading = 'loading',
  success = 'success',
  error = 'error',
}
export interface ITableData {
  area?: string
  people: IPeople[]
}
export interface IPeople {
  name: string
  phone: string
  attachments?: IAttachments[]
}
export interface IPeopleActions {
  name: string
  btnType: string
}
export interface IAttachments {
  name: string
  attachment: string
}
export interface IStats {
  name: string
  stat: string
}
export interface INavigation {
  title: string
  items: INavigationItem[]
}

export interface INavigationItem {
  name: string
  icon: any
  children?: INavigationItemChild[]
  route?: RoutesEnum
  length?: number | null
}
export interface INavigationItemChild {
  name: string
  num?: number
  route?: RoutesEnum
}
export interface IOrder {
  id: number
  // _id: string;
  // userId?: string;
  customerInfo: string
  providerInfo: string
  serviceId?: string
  workTime?: number
  startLocation?: string
  endLocation?: string
  note?: string
  quantity: number
  workingDate: string
  requestDate: string
  rejectDate?: string
  acceptDate: string
  completeDate?: string
}

export interface IStatsWithLinkAndIcon {
  id: number
  name: string
  stat: string
  icon: any
  change: string
  changeType: StatsChangeType
  route: string
}

export enum StatsChangeType {
  decrease = 'decrease',
  increase = 'increase',
}
