import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppDispatch } from '../../../../app/store'
import { classNames } from '../../../../app/utils/helper'
import { IPeople } from '../../../../app/utils/interfaces'
import { selectAuth } from '../../../auth/authSlice'
import ErrorBtn from '../../../components/ErrorBtn'
import PrimaryBtn from '../../../components/PrimaryBtn'
import ToggleWithIcon from '../../../components/ToggleWithIcon'
import { ICategory } from './categorySlice'

interface ICategoryListTableBodyProps {
  categories: ICategory[]
  onStatusClick: any
  onDeleteClick: any
}
const imgUrl = 'https://api.mueadaat.com/'
let tmpImg =
  'https://images.unsplash.com/photo-1603814744450-36f978490b11?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'
export default function CategoryListTableBody({
  categories,
  onStatusClick,
  onDeleteClick,
}: ICategoryListTableBodyProps) {
  const dispatch: AppDispatch = useDispatch()
  const authState = useSelector(selectAuth)
  let tmp = categories.map((cate, personIdx) => (
    <tr
      key={personIdx}
      className={classNames(personIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
    >
      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
        {personIdx + 1}
      </td>
      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
        <div className='w-12'>{cate?.image && <img src={`${imgUrl}${cate.image.path}`} />}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>{cate.title}</td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>{cate.sortOrder}</td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <ToggleWithIcon
          enabled={cate.enabled}
          setEnabled={() => {
            onStatusClick(cate._id, cate.enabled)
          }}
        />
      </td>
      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
        <div className='flex flex-row gap-5'>
          <Link to={`${cate._id}`}>
            <PrimaryBtn text='edit' />
          </Link>
          <ErrorBtn
            text='delete'
            onClick={() => {
              onDeleteClick(cate._id)
            }}
          />
        </div>
      </td>
    </tr>
  ))
  return <>{tmp}</>
}
