import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../app/store'
import { selectAuth } from '../../auth/authSlice'
import CustomNotification from '../../components/notification'
import { sendNotification } from './notificationSlice'
import SelectUsers from './SelectUsers'
interface IFormInput {
  title: string
  desc: string
}
interface ICheckbox {
  Guests: {
    enabled: boolean
    type: 0
  }
  Customers: {
    enabled: boolean
    type: 1
  }
  Providers: {
    enabled: boolean
    type: 2
  }
}
export default function PushNotifications() {
  const [show, setShow] = useState(false)
  const dispatch: AppDispatch = useDispatch()
  const authState = useSelector(selectAuth)
  const notifyState = useSelector((state: RootState) => state.notification)
  const [checkboxName, setCheckboxName] = useState<any>({
    Guests: {
      enabled: false,
      type: 0,
    },
    Customers: {
      enabled: false,
      type: 1,
    },
    Providers: {
      enabled: false,
      type: 2,
    },
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>()
  const onSubmit = async (data: IFormInput) => {
    const arr = []
    for (const key in checkboxName) {
      if (checkboxName[key].enabled === true) arr.push(checkboxName[key].type)
    }
    const payload = {
      type: arr,
      title: data.title,
      body: data.desc,
      token: authState.user.token,
    }
    await dispatch(sendNotification(payload))
    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 3000)
  }

  return (
    <div className='h-screen bg-gray-50'>
      <CustomNotification message={notifyState.notifyMessage!} show={show} setShow={setShow} />
      <div className='mx-auto max-w-2xl space-y-6 px-4 pt-8'>
        <div className='bg-white px-4 py-5 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg sm:p-6'>
          <h3 className='text-xl font-medium leading-6 text-gray-900'>Push Notification</h3>
          <div className='mt-6 flex flex-row gap-10'>
            <div className='w-full space-y-4'>
              <div className=''>
                <label
                  htmlFor='first-name'
                  className={`block text-sm font-medium text-gray-700 ${
                    !errors.title?.message ? 'text-gray-700' : 'text-red-600'
                  }`}
                >
                  Title {errors.title?.message}
                </label>
                <input
                  type='text'
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                    !errors.title?.message
                      ? 'border focus:border-indigo-500 focus:ring-indigo-500'
                      : 'border-red-500 focus:border-red-500 focus:ring-red-500 '
                  }`}
                  {...register('title', {
                    required: {
                      value: true,
                      message: 'is required',
                    },
                  })}
                />
              </div>

              <div className=''>
                <label
                  htmlFor='about'
                  className={`block text-sm font-medium text-gray-700 ${
                    !errors.desc?.message ? 'text-gray-700' : 'text-red-600'
                  }`}
                >
                  Description {errors.desc?.message}
                </label>
                <div className='mt-1'>
                  <textarea
                    rows={3}
                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                      !errors.desc?.message
                        ? 'border focus:border-indigo-500 focus:ring-indigo-500'
                        : 'border-red-500 focus:border-red-500 focus:ring-red-500 '
                    }`}
                    defaultValue={''}
                    {...register('desc', {
                      required: {
                        value: true,
                        message: 'is required',
                      },
                    })}
                  />
                </div>
              </div>
              <SelectUsers checkboxName={checkboxName} setCheckboxName={setCheckboxName} />
            </div>
            <div className='flex w-2/3 flex-col-reverse justify-between space-y-4 '>
              {/* <div className=''>
                <div>
                  <label
                    className={`block text-sm font-medium text-gray-700 ${
                      !errors.attachments?.message ? 'text-gray-700' : 'text-red-600'
                    }`}
                  >
                    Cover Image {errors.attachments?.message}
                  </label>
                  <div
                    className={`mt-1 rounded-md border-2 border-dashed   ${
                      !errors.attachments?.message ? 'border-gray-300' : 'border-red-500  '
                    }`}
                  >
                    <Dropzone onDrop={onDrop} />
                  </div>
                </div>
              </div> */}
              <div className='  text-right '>
                <button
                  type='button'
                  onClick={handleSubmit(onSubmit)}
                  className='inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
