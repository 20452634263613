import { Link } from 'react-router-dom'
import { classNames } from '../../../../../app/utils/helper'
import ErrorBtn from '../../../../components/ErrorBtn'
import PrimaryBtn from '../../../../components/PrimaryBtn'
import SuccessBtn from '../../../../components/SuccessBtn'
import { IService } from '../service_list/serviceSlice'

interface IProvidersListProps {
  services: IService[]
  onAcceptClick: any
  onDeleteClick: any
}
export default function ServiceRequestsTable({
  services,
  onAcceptClick,
  onDeleteClick,
}: IProvidersListProps) {
  let tmp = services.map((service, personIdx) => (
    <tr
      key={personIdx}
      className={classNames(personIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
    >
      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
        {personIdx + 1}
      </td>
      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
        <Link to={`/services/${service.serviceId}`}>{service.description}</Link>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        {service.category.title}
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        {service.subCategory.title}
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        {service.provider.fullName}
      </td>
      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
        <div className='flex flex-row gap-5'>
          <SuccessBtn
            text='Accept'
            onClick={() => {
              onAcceptClick(service.serviceId)
            }}
          />
          <ErrorBtn text='deny' onClick={() => onDeleteClick(service.serviceId)} />
        </div>
      </td>
    </tr>
  ))
  return <>{tmp}</>
}
