import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import Login from './pages/auth/Login'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { RoutesEnum } from './app/routes'
import { store } from './app/store'
import { Provider } from 'react-redux'
import AuthProvider from './app/hooks/RequireAuth'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <AuthProvider>
            <Routes>
              <Route path={RoutesEnum.Login} element={<Login />} />
              <Route path='/*' element={<App />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
)
