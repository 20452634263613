import { useEffect, useState } from 'react'

import { useAuthContext } from './app/hooks/RequireAuth'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { RoutesEnum } from './app/routes'
import Sidebar from './pages/components/Sidebar'
import Home from './pages/dashboard/home/Home'
import ProviderRequests from './pages/dashboard/provider_managment/requests/Requests'
import PushNotifications from './pages/dashboard/promotion_managment/PushNotifications'
import AppSettings from './pages/dashboard/system_settings/AppSettings'
import Navbar from './pages/components/Navbar'
import CategorySetup from './pages/dashboard/service_managment/category_setup/CategorySetup'
import ServicesRequests from './pages/dashboard/service_managment/services/service_requests/ServiceRequests'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser, selectAuth } from './pages/auth/authSlice'
import { AppDispatch } from './app/store'
import RequestDetails from './pages/dashboard/provider_managment/requests/RequestDetails'
import ProviderDetails from './pages/dashboard/provider_managment/provider_list/ProviderDetails'
import OrderDetails from './pages/dashboard/orders/OrderDetails'
import SubCategorySetup from './pages/dashboard/service_managment/category_setup/SubCategory'
import TermsAndConditions from './pages/dashboard/system_settings/TermsAndConditions'
import CategoryDocuments from './pages/dashboard/service_managment/category_setup/CategoryDocuments'
import ServiceDetails from './pages/dashboard/service_managment/services/service_list/ServiceDetails'
import CustomerRequests from './pages/dashboard/customers_managment/customer_requests/CustomerRequests'
import { RequestStatus } from './app/utils/interfaces'
import { getCustomers } from './pages/dashboard/customers_managment/customer_list/customerSlice'
import { getProvidersRequests } from './pages/dashboard/provider_managment/requests/providerRequestsSlice'
import {
  getServices,
  getDisabledServices,
} from './pages/dashboard/service_managment/services/service_list/serviceSlice'
import { EditCategory } from './pages/dashboard/service_managment/category_setup/EditCategory'
import { Toaster } from 'react-hot-toast'
import { CustomerPage } from './pages/dashboard/customers_managment/customer_list/CustomerPage'
import ServicesPage from './pages/dashboard/service_managment/services/service_list/ServicesPage'
import { ProviderListPage } from './pages/dashboard/provider_managment/provider_list/ProviderListPage'
import { OrdersPage } from './pages/dashboard/orders/OrdersPage'

export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()
  let currentLocation = location.pathname.split('').slice(1, location.pathname.length).join('')
  if (currentLocation == '') currentLocation = 'home'
  const [currentPage, setCurrentPage] = useState(currentLocation)
  const { isAuthed, token, setAuthed } = useAuthContext()
  const authState = useSelector(selectAuth)
  const dispatch: AppDispatch = useDispatch()
  let navigate = useNavigate()

  useEffect(() => {
    if (authState.status === RequestStatus.success) {
      dispatch(getCustomers(authState.user.token))
      dispatch(getProvidersRequests(authState.user.token))
      dispatch(getServices(authState.user.token)).then(() => dispatch(getDisabledServices()))
    }
  }, [authState.status])

  return isAuthed ? (
    <div className='min-h-full'>
      <Toaster position='top-center' />
      {/* <SidebarMobile sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
      <Sidebar setCurrentPage={setCurrentPage} currentPage={currentPage} />
      <div className='flex flex-1 flex-col lg:pl-64'>
        <main>
          <Navbar
            setSidebarOpen={setSidebarOpen}
            fullName={authState.user.fullName}
            onLogout={handleLogout}
          />
          <Routes>
            <Route
              path={RoutesEnum.Home}
              element={<Home setCurrentPage={setCurrentPage} currentPage={currentPage} />}
            />
            <Route path='/' element={<Navigate to={RoutesEnum.Home} replace />} />
            <Route path={RoutesEnum.ProviderRequests} element={<ProviderRequests />} />
            <Route path={RoutesEnum.PushNotifications} element={<PushNotifications />} />
            <Route path={RoutesEnum.AllOrders} element={<OrdersPage />} />
            <Route path={RoutesEnum.ProviderList} element={<ProviderListPage />} />
            {/* <Route path={RoutesEnum.AddNewProvider} element={<AddProvider />} /> */}
            <Route path={RoutesEnum.AppSettings} element={<AppSettings />} />
            <Route path={RoutesEnum.CategorySetup} element={<CategorySetup />} />
            <Route path={RoutesEnum.SubCategorySetup} element={<SubCategorySetup />} />
            <Route path={RoutesEnum.ServicesList} element={<ServicesPage />} />
            <Route path={RoutesEnum.ServicesRequests} element={<ServicesRequests />} />
            {/* <Route path={RoutesEnum.AddCustomer} element={<AddCustomer />} /> */}
            <Route path={RoutesEnum.CustomerRequests} element={<CustomerRequests />} />
            <Route path={RoutesEnum.CustomerList} element={<CustomerPage />} />
            <Route path={RoutesEnum.TermsAndConditions} element={<TermsAndConditions />} />
            <Route path={RoutesEnum.AddCategoryDocuments} element={<CategoryDocuments />} />
            <Route path='order/:id' element={<OrderDetails />} />
            <Route path='requests/:id' element={<RequestDetails />} />
            <Route path='provider-list/:id' element={<ProviderDetails />} />
            <Route path='services/:id' element={<ServiceDetails />} />
            <Route path={`${RoutesEnum.CategorySetup}/:id`} element={<EditCategory />} />
            <Route path={`${RoutesEnum.SubCategorySetup}/:id`} element={<EditCategory />} />
          </Routes>
        </main>
      </div>
    </div>
  ) : (
    <Navigate replace to='/login' />
  )

  function handleLogout() {
    localStorage.clear()
    dispatch(logoutUser())
    setAuthed(false)
    navigate('/login', { replace: true })
  }
}
