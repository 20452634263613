import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { SpinnerRoundFilled } from 'spinners-react'
import { api } from '../../../../api/axios_instance'
import Header from '../../../components/Header'
import { CustomersTable } from './customers-table/CustomerTable'

const CustomerPage = () => {
  const [page, setPage] = useState(0)
  const queryClient = useQueryClient()

  const customersQuery = useQuery({
    queryKey: ['customers', 'list', page],
    queryFn: async () => {
      const response = await api.get(`/user?page=${page}&limit=10`)
      return response.data
    },
    keepPreviousData: true,
  })

  useEffect(() => {
    // prefetch next page
    queryClient.prefetchQuery({
      queryKey: ['customers', 'list', page + 1],
      queryFn: async () => {
        const response = await api.get(`/user?page=${page + 1}&limit=10`)
        return response.data
      },
    })
  }, [page])

  const toNextPage = () => {
    setPage(page + 1)
  }

  const toPreviousPage = () => {
    setPage(page - 1)
  }

  if (customersQuery.isLoading) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  }

  if (customersQuery.isError) {
    return <div>error</div>
  }

  return (
    <div className=' w-full bg-gray-50'>
      <div className='mx-auto  max-w-6xl px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-col pt-8 pb-10'>
          <Header text='Customer List' />
          <div className='mt-4'></div>
          <CustomersTable
            customers={customersQuery.data.user}
            currentPage={page}
            pageSize={Math.ceil(customersQuery.data.totalSize / 10)}
            toNextPage={toNextPage}
            toPreviousPage={toPreviousPage}
          />
        </div>
      </div>
    </div>
  )
}

export { CustomerPage }
