import { useState } from 'react'
import ToggleWithIcon from '../../../../../../components/ToggleWithIcon'
import toast from 'react-hot-toast'
import { api } from '../../../../../../../api/axios_instance'

const StatusCell = ({ data }: any) => {
  const [enabled, setEnabled] = useState(data?.isActive)

  return (
    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
      <ToggleWithIcon
        enabled={enabled}
        setEnabled={() => {
          //   onStatusClick(service.serviceId, service.isActive)
          toast.promise(toggleEnabled(data?.serviceId, !enabled), {
            loading: 'قيد المعالجة',
            success: () => {
              setEnabled(!enabled)
              return <span>تمت العملية بنجاح</span>
            },
            error: <span>حدث خطأ الرجاء إعادة المحاولة</span>,
          })
        }}
      />
    </td>
  )
}

function toggleEnabled(id: string, enabled: boolean) {
  const res = api.put(`/services/${id}`, { isActive: enabled })
  return res
}

export default StatusCell
