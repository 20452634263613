export enum RoutesEnum {
  Login = '/login',
  Home = 'home',
  ProviderRequests = 'requests',
  ProviderList = 'provider-list',
  AddNewProvider = 'add-new-provider',
  PushNotifications = 'push-notifications',
  AllOrders = 'all-orders',
  AppSettings = 'app-settings',
  CategorySetup = 'category-setup',
  ServicesList = 'services-list',
  ServicesRequests = 'services-requests',
  AddCustomer = 'add-customer',
  CustomerList = 'customer-list',
  SubCategorySetup = 'sub-category',
  TermsAndConditions = 'terms-and-conditions',
  AddCategoryDocuments = 'add-category-documents',
  CustomerRequests = 'customer-requests',
}
