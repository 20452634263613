import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '../../../../api/axios_instance'
import { RequestStatus } from '../../../../app/utils/interfaces'

interface IProviderListState {
  providersRequests: {
    data: IProviderRequest[]
    status: RequestStatus
    error?: string
    length: number
  }
  rejectedProviders: {
    data?: any
    status: RequestStatus
    error?: string
    length: number
  }
  modalData?: {
    status: number
    modalId: string
    actionType: string
  }
  requestById: {
    data?: IRequestById
    status: RequestStatus
    error?: string
  }
  tab: string
}

export interface IRequestById {
  user: {
    fullName?: string
    phone: string
  }
  dataRequest: {
    providerDocument?: IProviderDocument[]
    createdAt: string
  }
}

export interface IProviderDocument {
  path?: string
  title?: string
}
export interface IProviderRequest {
  _id: string
  fullName: string
  phone: string
  statusRequest?: 0 | 1
  //   providerDocument?: IProviderDocument[]
}

// export const selectAuth = (state: RootState) => state.auth

const initialState: IProviderListState = {
  providersRequests: { status: RequestStatus.idle, length: 0, data: [] },
  rejectedProviders: { status: RequestStatus.idle, length: 0 },
  requestById: { status: RequestStatus.idle },
  tab: 'Requests',
}
// export const loginUser = createAsyncThunk('auth/login', async (user: ILoginFormInput) => {
//   const response = await api.post('/login', user)
//   return response.data
// })

export const getProvidersRequests = createAsyncThunk(
  'provider-requests/getProvidersRequests',
  async (token: any) => {
    const response = await api.get('/provider/provider-request', {
      headers: {
        authorization: token,
      },
    })
    return response.data.ProviderRequest
  },
)
export const getRejectedProvidersRequests = createAsyncThunk(
  'provider-requests/getRejectedProvidersRequests',
  async (token: any) => {
    const response = await api.post(
      '/provider/provider-request/reject',
      {},
      {
        headers: {
          authorization: token,
        },
      },
    )
    return response.data.ProviderRequest
  },
)
export const updateProviderRequest = createAsyncThunk(
  'provider-requests/updateProviderRequests',
  async (payload: any) => {
    const response = await api.put(
      `/provider/provider-request/${payload.id}`,
      { statusRequest: payload.status },
      {
        headers: {
          authorization: payload.token,
        },
      },
    )
    return payload.id
  },
)

export const getProviderRequestById = createAsyncThunk(
  'provider-requests/getProviderRequestById',
  async (payload: any) => {
    const response = await api.get(`/provider/provider-request/${payload.id}`, {
      headers: {
        authorization: payload.token,
      },
    })

    return response.data
  },
)
export const sendUpdateMessage = createAsyncThunk(
  'provider-requests/sendUpdateMessage',
  async (payload: any) => {
    const response = await api.put(
      `/provider/provider-request/${payload.id}`,
      {
        message: payload.message,
      },
      {
        headers: {
          authorization: payload.token,
        },
      },
    )

    return response.data
  },
)

export const providerRequestsSlice = createSlice({
  name: 'providerRequests',
  initialState,
  reducers: {
    setModalId(state, action) {
      state.modalData = action.payload
    },
    resetUserById(state) {
      state.requestById.status = RequestStatus.idle
    },
    setTabGlobal(state, action) {
      state.tab = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProvidersRequests.pending, (state, action) => {
        state.providersRequests.status = RequestStatus.loading
      })
      .addCase(getProvidersRequests.fulfilled, (state, action) => {
        state.providersRequests.status = RequestStatus.success
        state.providersRequests.data = action.payload
        state.providersRequests.length = action.payload.length
      })
      .addCase(getProvidersRequests.rejected, (state, action) => {
        state.providersRequests.status = RequestStatus.error
        state.providersRequests.error = action.error.message
      })

    builder
      .addCase(getRejectedProvidersRequests.pending, (state, action) => {
        state.rejectedProviders.status = RequestStatus.loading
      })
      .addCase(getRejectedProvidersRequests.fulfilled, (state, action) => {
        state.rejectedProviders.status = RequestStatus.success
        state.rejectedProviders.data = action.payload
        state.rejectedProviders.length = action.payload.length
      })
      .addCase(getRejectedProvidersRequests.rejected, (state, action) => {
        state.rejectedProviders.status = RequestStatus.error
        state.rejectedProviders.error = action.error.message
      })

    builder.addCase(updateProviderRequest.fulfilled, (state, action) => {
      state.providersRequests.status = RequestStatus.success
      if (state.tab === 'Requests') {
        state.providersRequests.data = state.providersRequests.data?.filter(
          (elm) => elm._id !== action.payload,
        )
        state.providersRequests.length = state.providersRequests.data.length
      } else {
        state.rejectedProviders.data = state.rejectedProviders.data?.filter(
          (elm: any) => elm._id !== action.payload,
        )
        state.rejectedProviders.length = state.rejectedProviders.data.length
      }
    })

    builder
      .addCase(getProviderRequestById.pending, (state, action) => {
        state.requestById.status = RequestStatus.loading
      })
      .addCase(getProviderRequestById.fulfilled, (state, action) => {
        state.requestById.status = RequestStatus.success
        state.requestById.data = action.payload
      })
      .addCase(getProviderRequestById.rejected, (state, action) => {
        state.requestById.status = RequestStatus.error
        state.requestById.error = action.error.message
      })
  },
})

export default providerRequestsSlice.reducer
export const { setModalId, resetUserById, setTabGlobal } = providerRequestsSlice.actions
