import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '../../api/axios_instance'
import { RootState } from '../../app/store'
import { RequestStatus } from '../../app/utils/interfaces'
import { ILoginFormInput } from './Login'

export interface AuthState {
  user: {
    fullName?: string
    email?: string
    token?: string
  }
  status: RequestStatus
  error?: string
}
export const selectAuth = (state: RootState) => state.auth

const initialState: AuthState = {
  user: {},
  status: RequestStatus.idle,
}
export const loginUser = createAsyncThunk('auth/login', async (user: ILoginFormInput) => {
  const response = await api.post('/login', user)
  return response.data
})

export const getProfile = createAsyncThunk('auth/profile', async (token: any) => {
  const response = await api.get('/profile', {
    headers: {
      authorization: token,
      'Content-Type': 'application/json',
    },
  })
  return { data: response.data, token: token }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action) {
      state.user.token = action.payload
    },
    logoutUser(state) {
      state.user = {}
      state.status = RequestStatus.idle
      state.error = ''
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.status = RequestStatus.loading
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = RequestStatus.success
        state.user = action.payload
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = RequestStatus.error
        state.error = action.error.message
      })

    builder
      .addCase(getProfile.pending, (state, action) => {
        state.status = RequestStatus.loading
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = RequestStatus.success
        state.user = { ...action.payload.data, token: action.payload.token }
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.status = RequestStatus.error

        state.error = action.error.message
      })
  },
})

export default authSlice.reducer
export const { setToken, logoutUser } = authSlice.actions
