import { Fragment, useState } from 'react'
import { CategoryListTableHeader, providerListTabs } from '../../../../app/utils/data-mock'
import CustomTable from '../../../components/CustomTable'
import Header from '../../../components/Header'
import Tabs from '../../../components/Tabs'
import CategoryListTableBody from './CategoryListTableBody'
import { ICategory } from './categorySlice'

interface ICategoryListProps {
  categories?: ICategory[]
  onStatusClick: any
  onDeleteClick: any
}
export default function SubCategoryList({
  categories = [],
  onStatusClick,
  onDeleteClick,
}: ICategoryListProps) {
  const [tab, setTab] = useState('All')
  return (
    <div className='mx-auto  max-w-6xl px-4 sm:px-6 lg:px-8'>
      <div className='flex flex-col pt-8'>
        <Header text='Sub Category List' />
        <div className='mt-4'></div>
        {/* <Tabs currentTab={tab} setTab={setTab} tabs={providerListTabs} /> */}
        <CustomTable tableHeaders={CategoryListTableHeader}>
          <CategoryListTableBody
            onStatusClick={onStatusClick}
            categories={categories}
            onDeleteClick={onDeleteClick}
          />
        </CustomTable>
      </div>
    </div>
  )
}
