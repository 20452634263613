import { PhoneCell } from './PhoneCell'
import TextCell from './TextCell'

const SERVICES_COLUMNS = [
  {
    Header: 'Name',
    Cell: ({ ...props }) => <TextCell data={props.data[props.row.index]} />,
  },
  {
    Header: 'Phone',
    Cell: ({ ...props }) => <PhoneCell data={props.data[props.row.index]} />,
  },
  // {
  //   Header: 'Provider',
  //   Cell: ({ ...props }) => <ProviderCell data={props.data[props.row.index]} />,
  // },
  // {
  //   Header: 'status',
  //   Cell: ({ ...props }) => <StatusCell data={props.data[props.row.index]} />,
  // },
]

export { SERVICES_COLUMNS }
