import { ICategoryDocument } from './categorySlice'

interface ISelectDocumentProps {
  setDocuments: any
  documentsState: string[]
  documents: ICategoryDocument[]
}
export default function SelectDocument({
  documents,
  setDocuments,
  documentsState,
}: ISelectDocumentProps) {
  return (
    <fieldset className='space-y-2'>
      <label className='block text-sm font-medium text-gray-700'>Select Required Documents</label>
      {documents.map((elm) => {
        return (
          <div className='relative flex items-start'>
            <div className='flex h-5 items-center'>
              <input
                onChange={
                  (e) => {
                    if (e.target.checked === true) setDocuments([...documentsState, elm._id])
                    else setDocuments(documentsState.filter((str) => str !== elm._id))
                  }
                  // setcheckboxname({
                  //   ...checkboxname,
                  //   [e.target.name]: {
                  //     enabled: !checkboxname[e.target.name].enabled,
                  //     type: checkboxname[e.target.name].type,
                  //   },
                  // })
                }
                name={elm.title}
                type='checkbox'
                className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
              />
            </div>
            <div className='ml-3 text-sm'>
              <label htmlFor='comments' className='font-medium text-gray-700'>
                {elm.title}
              </label>
            </div>
          </div>
        )
      })}
    </fieldset>
  )
}
