import { Fragment, useEffect, useState } from 'react'
import Header from '../../../../components/Header'
import CustomTable from '../../../../components/CustomTable'
import Tabs from '../../../../components/Tabs'
import { requestsTabs, serviceListTableHeader } from '../../../../../app/utils/data-mock'
import ServiceRequestsTable from './ServiceRequestsTable'
import { useDispatch, useSelector } from 'react-redux'
import { SpinnerRoundFilled } from 'spinners-react'
import { AppDispatch, RootState } from '../../../../../app/store'
import { RequestStatus } from '../../../../../app/utils/interfaces'
import { selectAuth } from '../../../../auth/authSlice'
import {
  deleteServiceRequest,
  getDisabledServices,
  getServices,
  setModalId,
  updateServiceStatus,
} from '../service_list/serviceSlice'
import Modal from '../../../../components/Modal'
import CustomNotification from '../../../../components/notification'

export default function ServicesRequests() {
  const [tab, setTab] = useState('Requests')
  const dispatch: AppDispatch = useDispatch()
  const serviceState = useSelector((state: RootState) => state.services)
  const authState = useSelector(selectAuth)
  const [open, setOpen] = useState(false)
  const [openNotify, setOpenNotify] = useState(false)
  useEffect(() => {
    if (authState.status == RequestStatus.success) {
      dispatch(getServices(authState.user.token)).then(() => dispatch(getDisabledServices()))
    }
  }, [authState.status])
  let content
  if (serviceState.status === RequestStatus.loading) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  } else if (serviceState.status === RequestStatus.success) {
    content = (
      <ServiceRequestsTable
        services={serviceState.disabledServices.data}
        onAcceptClick={onAcceptClick}
        onDeleteClick={onDeleteClick}
      />
    )
  } else if (serviceState.status === RequestStatus.error) {
    content = <div>{serviceState.error}</div>
  }
  return (
    <div className=' w-full bg-gray-50'>
      <div className='mx-auto  max-w-6xl px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-col pt-8'>
          <Modal
            open={open}
            setOpen={setOpen}
            title='Operation confirmation'
            desc='By changing status this will affect the user app'
            onConfirm={async () => {
              dispatch(
                updateServiceStatus({
                  token: authState.user.token,
                  enabled: true,
                  id: serviceState.modalData?.id,
                }),
              )
              setTimeout(() => {
                setOpenNotify(true)
              }, 1000)
            }}
          />
          <CustomNotification
            message='updated category successfully'
            show={openNotify}
            setShow={setOpenNotify}
          />
          <Header text='Service Requests' />
          {/* <Tabs currentTab={tab} setTab={setTab} tabs={requestsTabs} /> */}
          <div className='mt-4'></div>
          <CustomTable tableHeaders={serviceListTableHeader}>{content}</CustomTable>
        </div>
      </div>
    </div>
  )
  async function onAcceptClick(id: string) {
    await dispatch(setModalId({ id: id, statusType: 'requests' }))
    setOpen(true)
  }
  async function onDeleteClick(id: string) {
    await dispatch(deleteServiceRequest({ id, token: authState.user.token }))
    setTimeout(() => {
      setOpenNotify(true)
    }, 1000)
  }
}
