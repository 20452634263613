export default function PrimaryBtn({ text }: { text: string }) {
  return (
    <button
      type='button'
      className='capitalize inline-flex items-center rounded-md border border-transparent bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-700 shadow-sm hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-offset-2'
    >
      {text}
    </button>
  )
}
