import { BanknotesIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

const TextCell = ({ data }: any) => {
  return (
    <div className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
      <div className='flex'>
        <Link to={`/provider-list/${data?._id}`} className='group space-x-2 truncate text-sm'>
          <p className='w-60 truncate text-start text-gray-500 group-hover:text-gray-900' dir='rtl'>
            {data.fullName}
          </p>
        </Link>
      </div>
    </div>
  )
}

export default TextCell
