import { Disclosure } from '@headlessui/react'
import { useSelector } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { RoutesEnum } from '../../app/routes'
import { RootState } from '../../app/store'
import { navigation } from '../../app/utils/data-mock'
import { classNames } from '../../app/utils/helper'

export default function Sidebar({ setCurrentPage, currentPage }: any) {
  const state = useSelector((state: RootState) => state)
  const requests: any = {
    'services-requests': state.services.disabledServices.length,
    requests: state.requests.providersRequests.length,
    'customer-requests': state.customers.customers.filter((elm) => elm.codeOtp !== 0).length,
  }
  return (
    <div className='hidden lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col'>
      <div className='flex flex-grow flex-col overflow-y-auto bg-gray-800  pt-5 pb-4'>
        <div className='flex flex-shrink-0 items-center px-4'>
          <h1 className='text-3xl text-white'>Heavy</h1>
        </div>
        <nav className=' mt-4 flex-1 space-y-1  px-4'>
          {navigation.map((elm, index) => (
            <>
              <h1 className='ml-1 py-4 text-sm font-bold uppercase text-gray-500' key={index}>
                {elm.title}
              </h1>
              {elm.items.map((item) => (
                <>
                  {!item.children ? (
                    <Link to={item.route!}>
                      <div key={item.name}>
                        <a
                          onClick={() => setCurrentPage(item.route)}
                          className={classNames(
                            item.route === currentPage
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex cursor-default items-center justify-between rounded-md px-2 py-2 text-sm font-medium',
                          )}
                        >
                          <div className='flex'>
                            <item.icon
                              className={classNames(
                                item.route === currentPage
                                  ? 'text-gray-300'
                                  : 'text-gray-400 group-hover:text-gray-300',
                                'mr-3 h-6 w-6 flex-shrink-0',
                              )}
                            />
                            {item.name}
                          </div>
                          {requests[item.route!] !== undefined ? (
                            <span
                              className='rounded-full px-2 py-1 bg-red-700 text-white '
                            >
                              {requests[item.route!]}
                            </span>
                          ) : null}
                        </a>
                      </div>
                    </Link>
                  ) : (
                    <Disclosure as='div' key={item.name} className='space-y-1'>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              ' text-gray-300 hover:bg-gray-700 hover:text-white',
                              'group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-left text-sm font-medium ',
                            )}
                          >
                            <item.icon
                              className='mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-200'
                              aria-hidden='true'
                            />
                            <span className='flex-1'>{item.name}</span>
                            <svg
                              className={classNames(
                                open ? 'rotate-90 text-gray-400' : 'text-gray-300',
                                'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400',
                              )}
                              viewBox='0 0 20 20'
                              aria-hidden='true'
                            >
                              <path d='M6 6L14 10L6 14V6Z' fill='currentColor' />
                            </svg>
                          </Disclosure.Button>
                          <Disclosure.Panel className='space-y-1'>
                            {item.children?.map((subItem) => (
                              <Link to={subItem.route!}>
                                <h1
                                  key={subItem.route}
                                  onClick={(e: any) => {
                                    setCurrentPage(subItem.route)
                                  }}
                                  className={classNames(
                                    subItem.route === currentPage
                                      ? 'bg-gray-900 text-white'
                                      : ' text-gray-300 hover:bg-gray-700 hover:text-white',
                                    'group flex w-full cursor-default items-center justify-between rounded-md py-2 pl-11 pr-2 text-sm  font-medium hover:bg-gray-700 hover:text-white',
                                  )}
                                >
                                  <span> {subItem.name}</span>
                                  {subItem.num && (
                                    <span
                                      style={{ fontSize: 13.5 }}
                                      className='ml-3 hidden rounded-full bg-red-600 py-0.5 px-2.5 font-bold text-white md:inline-block'
                                    >
                                      {subItem.num}
                                    </span>
                                  )}
                                </h1>
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )}
                </>
              ))}
            </>
          ))}
        </nav>
      </div>
    </div>
  )
}
