import { Fragment, useEffect, useState } from 'react'
import Header from '../../../components/Header'
import CustomTable from '../../../components/CustomTable'
import { customersListTableHeader } from '../../../../app/utils/data-mock'
import { AppDispatch, RootState } from '../../../../app/store'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuth } from '../../../auth/authSlice'
import { RequestStatus } from '../../../../app/utils/interfaces'
import { SpinnerRoundFilled } from 'spinners-react'
import { getCustomers } from '../customer_list/customerSlice'
import CustomerRequestsTable from './CustomerRequestsTable'

export default function CustomerRequests() {
  const [tab, setTab] = useState('All')
  const dispatch: AppDispatch = useDispatch()
  const customerState = useSelector((state: RootState) => state.customers)
  const authState = useSelector(selectAuth)

  useEffect(() => {
    if (authState.status == RequestStatus.success) {
      dispatch(getCustomers(authState.user.token))
    }
  }, [authState.status])
  let content
  if (customerState.status === RequestStatus.loading) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  } else if (customerState.status === RequestStatus.success) {
    // content = <ServiceListTable services={customerState.services} />
    content = (
      <CustomerRequestsTable
        customers={customerState.customers.filter((elm) => elm.codeOtp !== 0)}
      />
    )
  }
  return (
    <div className=' w-full bg-gray-50'>
      <div className='mx-auto  max-w-6xl px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-col pt-8'>
          <Header text='Customer List' />
          {/* <Stats stats={providerListStats} />
          <Tabs currentTab={tab} setTab={setTab} tabs={providerListTabs} /> */}
          <div className='mt-4'></div>
          <CustomTable tableHeaders={customersListTableHeader}>{content}</CustomTable>
        </div>
      </div>
    </div>
  )
}
