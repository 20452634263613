import { useState } from 'react'
import { classNames } from '../../../app/utils/helper'

interface ActionPanelProps {
  header: string
  desc: string
  onClick?: any
}
export default function ActionPanel({ header, desc, onClick }: ActionPanelProps) {
  const [btnClicked, setBtnClicked] = useState(false)
  return (
    <div className='w-full px-4 py-5 sm:p-6'>
      <h3 className='text-lg font-medium leading-6 text-gray-900'>{header}</h3>
      <div className='mt-2 sm:flex sm:items-start sm:justify-between'>
        <div className='max-w-xl text-sm text-gray-500'>
          <p>{desc}</p>
        </div>
        <div className='mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center'>
          <button
            onClick={() => {
              onClick()
            }}
            type='button'
            className={classNames(
              'bg-indigo-600 text-white  hover:bg-indigo-700 ',
              'inline-flex items-center rounded-md border border-transparent  px-4 py-2 font-medium  shadow-sm   sm:text-sm',
            )}
          >
            Send update
          </button>
        </div>
      </div>
    </div>
  )
}
