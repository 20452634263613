import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SpinnerRoundFilled } from 'spinners-react'
import { AppDispatch, RootState } from '../../../app/store'
import { RequestStatus } from '../../../app/utils/interfaces'
import { selectAuth } from '../../auth/authSlice'
import Header from '../../components/Header'
import ActionPanel from './ActionPanel'
import ActionPanelWithToggle from './ActionPanelWithToggle'
import EnterVersion, { IVersion } from './EnterVersion'
import { addVersionType, getSettings, updateMaintainceMode, updateSettings } from './systemSlice'
import Modal from '../../components/Modal'
import CustomNotification from '../../components/notification'

export default function AppSettings() {
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [notify, setNotify] = useState(false)
  const dispatch: AppDispatch = useDispatch()
  const authState = useSelector(selectAuth)
  const systemState = useSelector((state: RootState) => state.system)
  useEffect(() => {
    if (authState.status == RequestStatus.success) {
      dispatch(getSettings(authState.user.token))
    }
  }, [authState.status])

  let content
  if (systemState.appSettings.status === RequestStatus.loading) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  } else if (systemState.appSettings.status === RequestStatus.success) {
    let appSetting = { ...systemState.appSettings.data }
    content = (
      <>
        <EnterVersion
          title='Enter Update Version'
          open={open}
          setOpen={setOpen}
          onConfirm={async (version: IVersion) => {
            const android = version.android
            const ios = version.ios
            appSetting!.android = android
            appSetting!.ios = ios

            let payload: any = {
              token: authState.user.token,
              appSetting: {
                forceUpdate: appSetting?.forceUpdate,
                optionalUpdate: appSetting?.optionalUpdate,
                maintenance: appSetting?.maintenance,
                android: appSetting?.android,
                ios: appSetting?.ios,
              },
            }
            if (systemState.updateType === 'forceUpdate') {
              payload.appSetting.forceUpdate = true
            } else {
              payload.appSetting.optionalUpdate = true
            }
            await dispatch(updateSettings(payload))
            setNotify(true)
            await dispatch(getSettings(authState.user.token))
          }}
          defaultValue={{ android: appSetting?.android!, ios: appSetting?.ios! }}
        />
        <Modal
          title='Operation Confirmation'
          desc='By turning on maintaince mode the mobile app will be off only admin panel will be
            functional'
          onConfirm={async () => {
            let payload: any = {
              token: authState.user.token,
              appSetting: {
                forceUpdate: appSetting?.forceUpdate,
                optionalUpdate: appSetting?.optionalUpdate,
                maintenance: !appSetting?.maintenance,
                android: appSetting?.android,
                ios: appSetting?.ios,
              },
            }
            dispatch(updateMaintainceMode(!appSetting?.maintenance))
            await dispatch(updateSettings(payload))
            setNotify(true)
          }}
          setOpen={setOpenModal}
          open={openModal}
        />
        <CustomNotification
          message='updated settings successfully'
          show={notify}
          setShow={setNotify}
        />
        <ActionPanel
          header='Send required update'
          desc='By sending a required update the mobile app users cannot use the app only if
                    they update they can use it'
          onClick={() => {
            dispatch(addVersionType('forceUpdate'))
            setOpen(true)
          }}
        />
        <ActionPanel
          header='Send Optional Update'
          desc='By sending an optional update the mobile app users can use the app but they will
                    see a popup that tells them about the new update'
          onClick={() => {
            dispatch(addVersionType('optionalUpdate'))
            setOpen(true)
          }}
        />
        <ActionPanelWithToggle
          enabledState={appSetting?.maintenance!}
          header='Maintaince mode'
          desc='By turning on maintaince mode the mobile app will be off only admin panel will be
            functional'
          onToggleClick={() => {
            setOpenModal(true)
          }}
        />
      </>
    )
  } else if (systemState.appSettings.status === RequestStatus.error) {
    content = <p>{systemState.appSettings.error}</p>
  }
  return (
    <div className='h-screen w-full bg-gray-50'>
      <div className='mx-auto  max-w-6xl px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-col pt-8'>
          <Header text='App Settings' />
          <div className='mt-4 divide-y bg-white shadow ring-1 ring-gray-200 sm:rounded-lg'>
            {content}
          </div>
        </div>
      </div>
    </div>
  )
}
