import { Dialog, Menu, Transition } from '@headlessui/react'

import { Bars3CenterLeftIcon, BellIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'
import { classNames } from '../../app/utils/helper'

export default function Navbar({ setSidebarOpen, fullName, onLogout }: any) {
  return (
    <div className='flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow '>
      <button
        type='button'
        className='border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden'
        onClick={() => setSidebarOpen(true)}
      >
        <Bars3CenterLeftIcon className='h-6 w-6' aria-hidden='true' />
      </button>
      <div className='flex flex-1 flex-row-reverse px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8'>
        <div className='ml-4 flex items-center md:ml-6'>
          <button
            type='button'
            className='rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2'
          >
            <span className='sr-only'>View notifications</span>
            <BellIcon className='h-6 w-6' aria-hidden='true' />
          </button>

          {/* Profile dropdown */}
          <Menu as='div' className='relative ml-3'>
            <div>
              <Menu.Button className='flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50'>
                <img
                  className='h-8 w-8 rounded-full'
                  src='https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80'
                  alt=''
                />
                <span className='ml-3 hidden text-sm font-medium text-gray-700 lg:block'>
                  <span className='sr-only'>Open user menu for </span>
                  {fullName}
                </span>
                <ChevronDownIcon
                  className='ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block'
                  aria-hidden='true'
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href='#'
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm font-medium text-gray-700',
                      )}
                    >
                      Settings
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      onClick={onLogout}
                      href='#'
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm font-medium text-red-500',
                      )}
                    >
                      Logout
                    </a>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  )
}
