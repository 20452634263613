import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '../../../../api/axios_instance'
import { RequestStatus } from '../../../../app/utils/interfaces'

interface IProviderListState {
  providers: IProvider[]
  status: RequestStatus
  error?: string
  requestById: {
    data?: IRequestById
    status: RequestStatus
    error?: string
  }
}
export interface IProvider {
  _id: string
  fullName: string
  phone: string
  isActive?: boolean
  providerDocument?: IProviderDocument[]
  createdAt?: string
}

export interface IProviderDocument {
  filename: string
  path: string
  size: number
  isDefault: boolean
  typeFile: string
  title: string
  isActive: boolean
}

export interface IRequestById {
  user: {
    fullName?: string
    phone: string
  }
  provider: {
    providerDocument?: IProviderDocument[]
    createdAt: string
  }
}
// export const selectAuth = (state: RootState) => state.auth

const initialState: IProviderListState = {
  providers: [],
  status: RequestStatus.idle,
  requestById: {
    status: RequestStatus.idle,
  },
}
// export const loginUser = createAsyncThunk('auth/login', async (user: ILoginFormInput) => {
//   const response = await api.post('/login', user)
//   return response.data
// })

export const getProviders = createAsyncThunk('providers/getProviders', async (token: any) => {
  const response = await api.get('/provider', {
    headers: {
      authorization: token,
    },
  })
  return response.data.Provider
})

export const getProviderById = createAsyncThunk(
  'providers/getProviderById',
  async (payload: any) => {
    const response = await api.get(`/provider/${payload.id}`, {
      headers: {
        authorization: payload.token,
      },
    })

    return response.data
  },
)

export const deleteProviderById = createAsyncThunk(
  'providers/deleteProviderById',
  async (payload: any) => {
    const response = await api.delete(`/provider/${payload.id}`, {
      headers: {
        authorization: payload.token,
      },
    })
    return { id: payload.id }
  },
)

export const providerListSlice = createSlice({
  name: 'providerList',
  initialState,
  reducers: {
    resetById(state) {
      state.requestById.status = RequestStatus.idle
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProviders.pending, (state, action) => {
        state.status = RequestStatus.loading
      })
      .addCase(getProviders.fulfilled, (state, action) => {
        state.status = RequestStatus.success
        state.providers = action.payload
      })
      .addCase(getProviders.rejected, (state, action) => {
        state.status = RequestStatus.error
        state.error = action.error.message
      })

    builder
      .addCase(getProviderById.pending, (state, action) => {
        state.requestById.status = RequestStatus.loading
      })
      .addCase(getProviderById.fulfilled, (state, action) => {
        state.requestById.status = RequestStatus.success
        state.requestById.data = action.payload
      })
      .addCase(getProviderById.rejected, (state, action) => {
        state.requestById.status = RequestStatus.error
        state.requestById.error = action.error.message
      })

    builder.addCase(deleteProviderById.fulfilled, (state, action) => {
      state.providers = state.providers.filter((elm) => elm._id !== action.payload.id)
    })
  },
})

export default providerListSlice.reducer
export const { resetById } = providerListSlice.actions
