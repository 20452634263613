import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import authReducer from '../pages/auth/authSlice'
import ordersReducer from '../pages/dashboard/orders/orderSlice'
import notificationReducer from '../pages/dashboard/promotion_managment/notificationSlice'
import providerListReducer from '../pages/dashboard/provider_managment/provider_list/providerListSlice'
import providerRequestsReducer from '../pages/dashboard/provider_managment/requests/providerRequestsSlice'
import categoryReducer from '../pages/dashboard/service_managment/category_setup/categorySlice'
import systemReducer from '../pages/dashboard/system_settings/systemSlice'
import serviceReducer from '../pages/dashboard/service_managment/services/service_list/serviceSlice'
import customerReducer from '../pages/dashboard/customers_managment/customer_list/customerSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    providerList: providerListReducer,
    requests: providerRequestsReducer,
    notification: notificationReducer,
    orders: ordersReducer,
    category: categoryReducer,
    system: systemReducer,
    services: serviceReducer,
    customers: customerReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
