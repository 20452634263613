import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { IStatsWithLinkAndIcon } from '../../../app/utils/interfaces'
import { classNames } from '../../../app/utils/helper'
import { Link } from 'react-router-dom'

interface IStatsWithLinkAndIconProps {
  stats: IStatsWithLinkAndIcon[]
  length: number[]
  setCurrentPage: any
}
export default function StatsWithLinkAndIcon({
  stats,
  length,
  setCurrentPage,
}: IStatsWithLinkAndIconProps) {
  return (
    <div>
      <dl className=' grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3'>
        {stats.map((item, index) => (
          <div
            key={item.id}
            className='relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6'
          >
            <dt>
              <div className='absolute rounded-md bg-indigo-600 p-3'>
                <item.icon className='h-6 w-6 text-white' aria-hidden='true' />
              </div>
              <p className='ml-16 truncate text-sm font-medium text-gray-500'>{item.name}</p>
            </dt>
            <dd className='ml-16 flex items-baseline pb-6 sm:pb-7'>
              <p className='text-2xl font-semibold text-gray-900'>{length[index]}</p>
              <p
                className={classNames(
                  item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                  'ml-2 flex items-baseline text-sm font-semibold',
                )}
              >
                {/* {item.changeType === 'increase' ? (
                  <ArrowUpIcon
                    className='h-5 w-5 flex-shrink-0 self-center text-green-500'
                    aria-hidden='true'
                  />
                ) : (
                  <ArrowDownIcon
                    className='h-5 w-5 flex-shrink-0 self-center text-red-500'
                    aria-hidden='true'
                  />
                )} */}

                {/* <span className='sr-only'>
                  {' '}
                  {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by{' '}
                </span>
                {item.change} */}
              </p>
              <Link to={`/${item.route}`} replace>
                <div
                  className='absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6'
                  onClick={() => setCurrentPage(item.route)}
                >
                  <div className='text-sm'>
                    <a href='#' className='font-medium text-indigo-600 hover:text-indigo-500'>
                      {' '}
                      View all<span className='sr-only'> {item.name} stats</span>
                    </a>
                  </div>
                </div>
              </Link>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
