export default function Header({ text }: IHeaderProps) {
  return (
    <div className='sm:flex sm:items-center'>
      <div className='sm:flex-auto'>
        <h1 className='text-2xl font-semibold text-gray-900'>{text}</h1>
      </div>
    </div>
  )
}

interface IHeaderProps {
  text: string
}
