import { Switch } from '@headlessui/react'
import { classNames } from '../../../app/utils/helper'
import { useState } from 'react'
interface ActionPanelWithToggleProps {
  header: string
  desc: string
  enabledState: boolean
  onToggleClick: any
}
export default function ActionPanelWithToggle({
  header,
  desc,
  enabledState,
  onToggleClick,
}: ActionPanelWithToggleProps) {
  return (
    <Switch.Group as='div' className=' w-full px-4 py-5 sm:p-6'>
      <Switch.Label as='h3' className='text-lg font-medium leading-6 text-gray-900' passive>
        {header}
      </Switch.Label>
      <div className='mt-2 sm:flex sm:items-start sm:justify-between'>
        <div className='max-w-xl text-sm text-gray-500'>
          <Switch.Description>{desc}</Switch.Description>
        </div>
        <div className='mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center'>
          <Switch
            checked={enabledState}
            onChange={(val: boolean) => {
              onToggleClick()
            }}
            className={classNames(
              enabledState ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
            )}
          >
            <span
              aria-hidden='true'
              className={classNames(
                enabledState ? 'translate-x-5' : 'translate-x-0',
                'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              )}
            />
          </Switch>
        </div>
      </div>
    </Switch.Group>
  )
}
