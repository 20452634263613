interface ISuccessBtn {
  text: string
  onClick?: any
}
export default function SuccessBtn({ text, onClick }: ISuccessBtn) {
  return (
    <button onClick={onClick} className='rounded-md bg-green-600 px-4 py-2 capitalize text-gray-50 shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2  '>
      {text}
    </button>
  )
}
