import { ICategory } from './categorySlice'

interface ISelectMainCategoryProps {
  categories: ICategory[]
  mainCate: string
  setMainCate: any
}

export default function SelectMainCategory({
  categories,
  mainCate,
  setMainCate,
}: ISelectMainCategoryProps) {
  return (
    <div>
      <label htmlFor='priority' className='block text-sm font-medium text-gray-700'>
        Main Category
      </label>
      <select
        id='priority'
        name='priority'
        className='mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
        onChange={(e) => {
          const index = e.target.selectedIndex
          const el = e.target.childNodes[index] as HTMLInputElement
          const id = el.getAttribute('id')

          setMainCate(id)
        }}
      >
        {/* {priorityNumbers()} */}
        <option key={0}>Select Main Category</option>
        {categories.map((elm, index) => {
          return (
            <option key={index} id={elm._id}>
              {elm.title}
            </option>
          )
        })}
      </select>
    </div>
  )
}
