import { FC } from 'react'

type Props = {
  address: {
    country: string
    city: string
    district: string
  }
}
const AddressCell: FC<Props> = ({ address }) => {
  if (!address) return <div className='px-6'>Address not found</div>

  const { country, city, district } = address

  return (
    <div className='flex flex-col px-6'>
      <div className='text-sm text-gray-600'>
        {city} - {district}
      </div>
    </div>
  )
}

export { AddressCell }
