import { useQuery, useQueryClient } from 'react-query'
import { SpinnerRoundFilled } from 'spinners-react'
import { api } from '../../../../../api/axios_instance'
import Header from '../../../../components/Header'
import { ServicesTable } from './table/ServicesTable'
import usePaginationStore, { DEFAULT_PAGINATION_STATE } from '../../../../../app/store/tablesState'
import qs from 'qs'
import { useEffect } from 'react'

const TABLE_NAME = 'services-list'
const ServicesPage = () => {
  const tablePaginationStore = usePaginationStore()
  const queryClient = useQueryClient()

  // if there is a stored state for the table in the store get it
  // else use default pagination data
  const { currentPage, pageSize } =
    tablePaginationStore.tables[TABLE_NAME] ?? DEFAULT_PAGINATION_STATE

  const params = qs.stringify({ page: currentPage, limit: pageSize })
  const servicesQuery = useQuery(['services', 'list', params], async () => {
    const res = await api.get(`/services?${params}`)
    return res.data
  })

  useEffect(() => {
    const params = qs.stringify({ page: currentPage + 1, limit: pageSize })
    queryClient.prefetchQuery({
      queryKey: ['services', 'list', params],
      queryFn: async () => {
        const res = await api.get<{ Provider: any[]; pageSize: number }>(`/services?${params}`)
        return res.data
      },
    })
  }, [currentPage, pageSize])

  if (servicesQuery.isLoading) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  }

  if (servicesQuery.isError) {
    const error = servicesQuery.error as Error

    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <h2>{error.message}</h2>
      </div>
    )
  }

  const setPageIndex = (pageIndex: number) => {
    tablePaginationStore.setPaginationState(TABLE_NAME, {
      currentPage: pageIndex,
      pageSize: pageSize,
    })
  }

  const setPageSize = (pageSize: number) => {
    tablePaginationStore.setPaginationState(TABLE_NAME, {
      currentPage,
      pageSize: pageSize,
    })
  }

  return (
    <div className='w-full bg-gray-50 pb-10'>
      <div className='mx-auto  max-w-6xl px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-col pt-8'>
          <Header text='All Services' />
          <div className='mt-4'>
            <ServicesTable
              services={servicesQuery.data?.service}
              queryPageIndex={currentPage}
              queryPageSize={pageSize}
              queryPageCount={servicesQuery.data?.pageSize ?? 0}
              setQueryPageIndex={setPageIndex}
              setQueryPageSize={setPageSize}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesPage
