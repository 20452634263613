import {
  HomeIcon,
  UsersIcon,
  DocumentTextIcon,
  BellAlertIcon,
  Cog6ToothIcon,
  IdentificationIcon,
  UserPlusIcon,
  TagIcon,
  PuzzlePieceIcon,
  NewspaperIcon,
} from '@heroicons/react/24/outline'
import {
  INavigation,
  IOrder,
  IStats,
  IStatsWithLinkAndIcon,
  ITableData,
  ITabs,
  StatsChangeType,
} from './interfaces'
import { RoutesEnum } from '../routes'
export const locations: ITableData[] = [
  {
    area: 'Alwahda District',
    people: [
      {
        name: 'Saleh Qasem Ali Mohammed',
        phone: '+967 771231238',
        attachments: [
          { name: 'vehicle card', attachment: '' },
          { name: 'vehicle image', attachment: '' },
          { name: 'vehicle papers', attachment: '' },
        ],
      },
      {
        name: 'Qasem Saleh Ahmed Ali',
        phone: '+967 771231238',
        attachments: [
          { name: 'vehicle card', attachment: '' },
          { name: 'vehicle image', attachment: '' },
          { name: 'vehicle papers', attachment: '' },
        ],
      },
      {
        name: 'Mohammed Ali Qasem Saleh',
        phone: '+967 771231238',
        attachments: [
          { name: 'vehicle card', attachment: '' },
          { name: 'vehicle image', attachment: '' },
          { name: 'vehicle papers', attachment: '' },
        ],
      },
    ],
  },
  {
    area: 'Sawan District',
    people: [
      {
        name: 'Ali Mohammed Qasem Saleh',
        phone: '+967 771231238',
        attachments: [
          { name: 'vehicle card', attachment: '' },
          { name: 'vehicle image', attachment: '' },
          { name: 'vehicle papers', attachment: '' },
        ],
      },
      {
        name: 'Saleh Qasem Mohammed Ali',
        phone: '+967 771231238',
        attachments: [
          { name: 'vehicle card', attachment: '' },
          { name: 'vehicle image', attachment: '' },
          { name: 'vehicle papers', attachment: '' },
        ],
      },
      {
        name: 'Qasem Saleh Ali Mohammed',
        phone: '+967 771231238',
        attachments: [
          { name: 'vehicle card', attachment: '' },
          { name: 'vehicle image', attachment: '' },
          { name: 'vehicle papers', attachment: '' },
        ],
      },
    ],
  },
]
export const serviceList = [
  {
    area: 'Alwahda District',
    people: [
      {
        name: 'service name',
        owner: 'Saleh Qasem Ali Mohammed',
        category: 'category name',
      },
      {
        name: 'service name',
        owner: 'Qasem Saleh Ahmed Ali',
        category: 'category name',
      },
      {
        name: 'service name',
        owner: 'Mohammed Ali Qasem Saleh',
        category: 'category name',
      },
    ],
  },
  {
    area: 'Sawan District',
    people: [
      {
        name: 'service name',
        owner: 'Ali Mohammed Qasem Saleh',
        category: 'category name',
      },
      {
        name: 'service name',
        owner: 'Saleh Qasem Mohammed Ali',
        category: 'category name',
      },
      {
        name: 'service name',
        owner: 'Qasem Saleh Ali Mohammed',
        category: 'category name',
      },
    ],
  },
]
export const categoriesList = [
  {
    image:
      'https://images.unsplash.com/photo-1603814744450-36f978490b11?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
    name: 'categoryName',
    prior: '0',
  },
  {
    image:
      'https://images.unsplash.com/photo-1603814744450-36f978490b11?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
    name: 'categoryName',
    prior: '1',
  },
  {
    image:
      'https://images.unsplash.com/photo-1603814744450-36f978490b11?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
    name: 'categoryName',
    prior: '2',
  },
]
export const requestsTabs: ITabs[] = [
  { name: 'Requests', count: '8' },
  { name: 'Denied', count: '2' },
]
export const requestsTableHeader = ['id', 'name', 'phone', 'attachments', 'action']
export const ProvidersListTableHeader = ['id', 'name', 'phone', 'status', 'action']
export const customersListTableHeader = ['id', 'phone', 'otp']
export const serviceListTableHeader = [
  'id',
  'description',
  'category',
  'sub category',
  'provider',
  'action',
]
export const serviceRequestTableHeader = [
  'id',
  'name',
  'category',
  'attachments',
  'status',
  'action',
]
export const CategoryListTableHeader = ['id', 'Image', 'Name', 'Priority', 'status', 'action']
export const DocumentListTableHeader = ['id', 'Name', 'Status']
export const allOrdersTableHeaders = ['Order Id', 'Customer', 'Provider', 'Request Date', 'Action']
export const allOrders: IOrder[] = [
  {
    id: 100040,
    customerInfo: 'Mohammed Saleh',
    providerInfo: 'Ali Qasem',
    quantity: 1,
    requestDate: '12-Oct-2022 12:16am',

    acceptDate: '12-Oct-2022 12:16am',
    workingDate: '12-Oct-2022 12:16am',
  },
  {
    id: 100040,
    customerInfo: 'Mohammed Saleh',
    providerInfo: 'Ali Qasem',
    quantity: 1,
    requestDate: '12-Oct-2022 12:16am',

    acceptDate: '12-Oct-2022 12:16am',
    workingDate: '12-Oct-2022 12:16am',
  },
  {
    id: 100040,
    customerInfo: 'Mohammed Saleh',
    providerInfo: 'Ali Qasem',
    quantity: 1,
    requestDate: '12-Oct-2022 12:16am',

    acceptDate: '12-Oct-2022 12:16am',
    workingDate: '12-Oct-2022 12:16am',
  },
  {
    id: 100040,
    customerInfo: 'Mohammed Saleh',
    providerInfo: 'Ali Qasem',
    quantity: 1,
    requestDate: '12-Oct-2022 12:16am',

    acceptDate: '12-Oct-2022 12:16am',
    workingDate: '12-Oct-2022 12:16am',
  },
]
export const providerListTabs: ITabs[] = [
  { name: 'All', count: '8' },
  { name: 'Active', count: '2' },
  { name: 'Inactive', count: '2' },
]

export const Orders = [
  {
    id: 2,
    name: 'Mohammed saleh ',
    href: '#',
    amount: '20,000',
    status: 'success',
    date: 'July 11, 2020',
    datetime: '2020-07-11',
  },
  {
    id: 1,
    name: 'Qasem ali',
    href: '#',
    amount: '20,000',
    status: 'failed',
    date: 'July 11, 2020',
    datetime: '2020-07-11',
  },
  {
    id: 3,
    name: 'Saleh Ali',
    href: '#',
    amount: '20,000',
    status: 'processing',
    date: 'July 11, 2020',
    datetime: '2020-07-11',
  },
]
export const statusStyles: { [key: string]: string } = {
  Completed: 'bg-green-100 text-green-800',
  Pending: 'bg-yellow-100 text-yellow-800',
  Accepted: 'bg-yellow-100 text-yellow-800',
  'In Work': 'bg-gray-100 text-gray-800',
  Denied: 'bg-red-100 text-gray-800',
  'Out For Delivery': 'bg-gray-100 text-gray-800',
}

export const providerListStats: IStats[] = [
  { name: 'Total Providers', stat: '15' },
  { name: 'Active Providers', stat: '10' },
  { name: 'Onboarding Requests', stat: '3' },
  { name: 'Inactive Providers', stat: '5' },
]
export const serviceListStats: IStats[] = [
  { name: 'Total Services', stat: '15' },
  { name: 'Active Services', stat: '10' },
  { name: 'Inactive Services', stat: '5' },
  { name: 'Onboarding Requests', stat: '3' },
]

export const navigation: INavigation[] = [
  {
    title: 'Main',
    items: [{ name: 'Dashboard', icon: HomeIcon, route: RoutesEnum.Home }],
  },
  {
    title: 'Order Managment',
    items: [
      {
        name: 'Orders',
        icon: DocumentTextIcon,

        route: RoutesEnum.AllOrders,
      },
    ],
  },
  {
    title: 'Service Managment',
    items: [
      {
        name: 'Category Setup',
        icon: TagIcon,
        children: [
          { name: 'Categories', route: RoutesEnum.CategorySetup },
          { name: 'Sub Categories', route: RoutesEnum.SubCategorySetup },
          { name: 'Documents', route: RoutesEnum.AddCategoryDocuments },
        ],
      },
      {
        name: 'Services',
        icon: PuzzlePieceIcon,
        route: RoutesEnum.ServicesList,
      },
      {
        name: 'Service Requests',
        icon: IdentificationIcon,
        route: RoutesEnum.ServicesRequests,
      },
    ],
  },
  {
    title: 'Provider Managment',
    items: [
      {
        name: 'Providers',
        icon: UsersIcon,
        route: RoutesEnum.ProviderList,
      },
      {
        name: 'Requests',
        icon: IdentificationIcon,
        route: RoutesEnum.ProviderRequests,
        length: 10,
      },
    ],
  },
  {
    title: 'Customers Managment',
    items: [
      {
        name: 'Customers',
        icon: UsersIcon,
        route: RoutesEnum.CustomerList,
      },
      {
        name: 'Requests',
        icon: IdentificationIcon,
        route: RoutesEnum.CustomerRequests,
      },
    ],
  },
  {
    title: 'Promotion Managment',
    items: [
      {
        name: 'Push Notifications',
        icon: BellAlertIcon,
        route: RoutesEnum.PushNotifications,
      },
    ],
  },
  {
    title: 'System Settings',
    items: [
      {
        name: 'App Settings',
        icon: Cog6ToothIcon,
        route: RoutesEnum.AppSettings,
      },
      {
        name: 'Terms & Conditions',
        icon: NewspaperIcon,
        route: RoutesEnum.TermsAndConditions,
      },
    ],
  },
]

export const overviewStats: IStatsWithLinkAndIcon[] = [
  {
    id: 1,
    name: 'Total Orders',
    stat: '60',
    icon: DocumentTextIcon,
    change: '40%',
    changeType: StatsChangeType.increase,
    route: RoutesEnum.AllOrders,
  },
  {
    id: 4,
    name: 'Total Services',
    stat: '5',
    icon: PuzzlePieceIcon,
    change: '10%',
    changeType: StatsChangeType.decrease,
    route: RoutesEnum.ServicesList,
  },
  {
    id: 2,
    name: 'Total Providers',
    stat: '20',
    icon: UsersIcon,
    change: '5%',
    changeType: StatsChangeType.increase,
    route: RoutesEnum.ProviderList,
  },
  {
    id: 3,
    name: 'Total Customers',
    stat: '5',
    icon: UserPlusIcon,
    change: '10%',
    changeType: StatsChangeType.decrease,
    route: RoutesEnum.CustomerList,
  },
]
