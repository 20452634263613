import { useEffect, useState } from 'react'
// Import the useDropzone hooks from react-dropzone
import { useDropzone } from 'react-dropzone'

export default function ImageUploader({ onDrop, defaultImage }: any) {
  const [src, setSrc] = useState()

  useEffect(() => {
    if (Boolean(defaultImage)) {
      setSrc(defaultImage)
    }
  }, [])
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
    accept: {
      'image/svg+xml': ['.svg'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    maxFiles: 1,
  })
  const [result, setResult] = useState()
  /* 
    useDropzone hooks exposes two functions called getRootProps and getInputProps
    and also exposes isDragActive boolean
  */

  return (
    <div className='flex w-full flex-col space-y-1  text-center'>
      {isDragActive ? (
        <p className='dropzone-content py-7'>Release to drop the files here</p>
      ) : (
        <div className=''>
          {acceptedFiles.length > 0 ? (
            acceptedFiles.map((acceptedFile, index) => {
              let file = acceptedFile
              let src = URL.createObjectURL(file)
              return (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div key={index}>
                    <img src={src} className='mx-auto w-20' />
                    <div className='mt-1'>{acceptedFile.name}</div>
                  </div>
                </div>
              )
            })
          ) : Boolean(defaultImage) ? (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div>
                <img src={defaultImage} className='mx-auto w-20' />
              </div>
            </div>
          ) : (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div>
                <svg
                  className='mx-auto h-12 w-12 text-gray-400 '
                  stroke='currentColor'
                  fill='none'
                  viewBox='0 0 48 48'
                  aria-hidden='true'
                >
                  <path
                    d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                <div className='text-sm text-gray-600'>
                  <p className='pl-1'>Click to upload files or drag and drop</p>
                </div>
                <p className='text-xs text-gray-500'>PNG, JPG, PDF</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
