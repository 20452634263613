interface ITableHeaderProps {
  headers: string[]
}
export default function TableHeader({ headers }: ITableHeaderProps) {
  return (
    <thead className=' bg-gray-100'>
      <tr>
        {headers.map((header, index) => (
          <th
            key={index}
            className='px-4 py-3.5 text-left text-sm font-semibold capitalize text-gray-900'
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
  )
}
