import SearchInput from './SearchInput'
import TableHeader from './TableHeader'

interface ITableProps {
  tableHeaders: string[]
  children?: React.ReactNode
}
export default function CustomTable({ children, tableHeaders }: ITableProps) {
  return (
    <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
      <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          {/* <SearchInput /> */}
          <table className='min-w-full'>
            <TableHeader headers={tableHeaders} />
            <tbody className='bg-white'>{children}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
