import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SpinnerRoundFilled } from 'spinners-react'
import { AppDispatch, RootState } from '../../../app/store'
import { overviewStats } from '../../../app/utils/data-mock'
import { RequestStatus } from '../../../app/utils/interfaces'
import { selectAuth } from '../../auth/authSlice'
import { getAllOrders, getHomeSummary } from '../orders/orderSlice'
import StatsWithLinkAndIcon from './StatsWithLinkAndIcon'
import { getProviders } from '../provider_managment/provider_list/providerListSlice'
import { OrdersTable } from './activity-table/ActivityTable'

export default function Home({ setCurrentPage, currentPage }: any) {
  const dispatch: AppDispatch = useDispatch()
  const summaryState = useSelector((state: RootState) => state.orders.summary)
  const ordersState = useSelector((state: RootState) => state.orders)

  const authState = useSelector(selectAuth)

  useEffect(() => {
    if (authState.status === RequestStatus.success) {
      dispatch(getHomeSummary(authState.user.token))
      dispatch(getAllOrders(authState.user.token))
    }
  }, [authState.status])

  let content
  if (
    summaryState.status === RequestStatus.loading ||
    ordersState.status === RequestStatus.loading
  ) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  } else if (
    summaryState.status === RequestStatus.success ||
    ordersState.status === RequestStatus.success
  ) {
    content = (
      <div>
        <div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8'>
          <h2 className='text-2xl font-medium leading-6 text-gray-900'>Overview</h2>
          <div className='mt-4 rounded-lg bg-white p-5 pb-6 shadow ring-1 ring-gray-100'>
            <StatsWithLinkAndIcon
              setCurrentPage={setCurrentPage}
              stats={overviewStats}
              length={[
                summaryState.data?.orders,
                summaryState.data?.services,
                summaryState.data?.providers,
                summaryState.data?.users,
              ]}
            />
          </div>
        </div>
        <h2 className='mx-auto mb-4 mt-10 max-w-6xl px-4 text-2xl font-medium leading-6 text-gray-900 sm:px-6 lg:px-8'>
          Recent orders
        </h2>
        <OrdersTable orders={ordersState.orders} />
      </div>
    )
  } else if (ordersState.status === RequestStatus.error) {
    content = <div>{ordersState.error}</div>
  }
  return <div className=' bg-gray-50 py-8 '>{content}</div>
}
