import { useForm } from 'react-hook-form'
import LoadingIcon from './LoadingIcon'
import { Navigate, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../app/hooks/RequireAuth'
import { useDispatch } from 'react-redux'
import { loginUser, selectAuth } from './authSlice'
import { AppDispatch } from '../../app/store'
import { useSelector } from 'react-redux/es/exports'
import { RequestStatus } from '../../app/utils/interfaces'
import { useState } from 'react'
import CustomNotification from '../components/notification'
export interface ILoginFormInput {
  email: string
  password: string
}

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormInput>()
  const dispatch: AppDispatch = useDispatch()
  const authState = useSelector(selectAuth)
  const navigate = useNavigate()
  const { isAuthed, setAuthed } = useAuthContext()
  const [openNotify, setOpenNotify] = useState(false)
  const onSubmit = async (data: ILoginFormInput) => {
    if (data) {
      await dispatch(loginUser(data))
    }
  }

  if (authState.status === RequestStatus.success) {
    localStorage.setItem('token', authState.user?.token!)
    setAuthed(true)
    return <Navigate replace to='/' />
  } else {
    return !isAuthed ? (
      <>
        <div className='flex h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8'>
          {authState.status === RequestStatus.error && (
            <CustomNotification
              message={authState.error!}
              show={openNotify}
              setShow={setOpenNotify}
            />
          )}
          <div className='shadow sm:mx-auto sm:w-full sm:max-w-md'>
            <div className='bg-white py-7  px-4 shadow sm:rounded-lg sm:px-10'>
              <div className='mb-12 sm:mx-auto sm:w-full sm:max-w-md'>
                <h2 className=' text-center text-3xl font-bold tracking-tight text-gray-900'>
                  Heavy Admin Login
                </h2>
              </div>
              <div className='space-y-4'>
                <div>
                  <label
                    htmlFor='email'
                    className={`block text-sm font-medium ${
                      !errors.email?.message ? 'text-gray-700' : 'text-red-600'
                    }`}
                  >
                    Email address {errors.email?.message}
                  </label>
                  <div className='mt-1'>
                    <input
                      id='email'
                      type='email'
                      autoComplete='email'
                      className={`block w-full appearance-none rounded-md  border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm ${
                        !errors.email?.message
                          ? 'border focus:border-indigo-500 focus:ring-indigo-500'
                          : 'border-red-500 focus:border-red-500 focus:ring-red-500 '
                      }`}
                      {...register('email', {
                        required: {
                          value: true,
                          message: 'is required',
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'is invalid',
                        },
                      })}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor='password'
                    className={`block text-sm font-medium ${
                      !errors.password?.message ? 'text-gray-700' : 'text-red-600'
                    }`}
                  >
                    Password {errors.password?.message}
                  </label>
                  <div className='mt-1'>
                    <input
                      id='password'
                      type='password'
                      autoComplete='current-password'
                      className={`block w-full appearance-none rounded-md  border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm ${
                        !errors.password?.message
                          ? 'border focus:border-indigo-500 focus:ring-indigo-500'
                          : 'border-red-500 focus:border-red-500 focus:ring-red-500 '
                      }`}
                      {...register('password', {
                        required: { value: true, message: 'is required' },
                      })}
                    />
                  </div>
                </div>

                <div>
                  <button
                    type='submit'
                    className='mt-6 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    onClick={handleSubmit(onSubmit)}
                  >
                    {authState.status === RequestStatus.loading ? <LoadingIcon /> : 'Sign in'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <Navigate replace to='/' />
    )
  }
}
