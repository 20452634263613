import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../../../../api/axios_instance'
import { RequestStatus } from '../../../../app/utils/interfaces'

interface ICategoryState {
  status: RequestStatus
  error?: string
  categories: ICategory[]
  length: number
  disabledCategory: {
    data?: any
    error?: string
    length: number
  }
  enabledCategory: {
    data?: any
    error?: string
    length: number
  }
  requestById: {
    data: ICategory[]
    status: RequestStatus
    error?: string
  }
  editCategory: {
    data?: ICategory
    status: RequestStatus
    error?: string
  }
  create: {
    status: RequestStatus
    error?: string
  }
  modalData?: {
    id: string
    enabled: boolean
    statusType: 'sub' | 'home'
  }
  tab: string
  documents: {
    data: ICategoryDocument[]
    status: RequestStatus
    error?: string
  }
}

const initialState: ICategoryState = {
  categories: [],
  length: 0,
  status: RequestStatus.idle,
  disabledCategory: {
    length: 0,
  },
  enabledCategory: {
    length: 0,
  },
  requestById: {
    data: [],
    status: RequestStatus.idle,
  },
  create: {
    status: RequestStatus.idle,
  },
  editCategory: {
    status: RequestStatus.idle,
  },
  tab: 'All',
  documents: { status: RequestStatus.idle, data: [] },
}

export interface ICategory {
  _id: string
  title: string
  iconName: string
  sortOrder: string
  enabled: boolean
  image: {
    path: string
  }
  createdAt: string
  updatedAt: string
  parentId?: string
  children: ICategory[]
}

export interface ICategoryDocument {
  statusType: number
  id: number
  _id: string
  title: string
  isActive: boolean
  deleted: boolean
  updatedAt: string
  createdAt: string
}

export const getCategoryById = createAsyncThunk(
  'category/getCategoryById',
  async (payload: any) => {
    const response = await api.get(`/category/${payload.id}`, {
      headers: {
        authorization: payload.token,
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  },
)

export const uploadImage = createAsyncThunk('category/uploadImage', async (payload: any) => {
  const response = await api.post(`/category/upload-image/${payload.id}`, payload.data, {
    headers: {
      authorization: payload.token,
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
})

export const updateCategory = createAsyncThunk('category/updateCategory', async (payload: any) => {
  const response = await api.put(`/category/${payload.id}`, payload.data, {
    headers: {
      authorization: payload.token,
    },
  })
  return response.data
})

export const addCategory = createAsyncThunk('category/addCategory', async (payload: any) => {
  const response = await api.post('/category', payload.data, {
    headers: {
      authorization: payload.token,
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
})

export const getAllCategory = createAsyncThunk('category/getAllCategory', async (token: any) => {
  const response = await api.get(`/category/list-category`, {
    headers: {
      authorization: token,
    },
  })
  return response.data.categories
})

export const getDocuments = createAsyncThunk('category/getDocuments', async (token: any) => {
  const response = await api.get(`/document/`, {
    headers: {
      authorization: token,
    },
  })
  return response.data.documents
})

export const createDocument = createAsyncThunk('category/createDocument', async (payload: any) => {
  const response = await api.post(
    '/document/',
    {
      title: payload.title,
    },
    {
      headers: {
        authorization: payload.token,
      },
    },
  )
  return response.data
})

export const updateDocument = createAsyncThunk('category/updateDocument', async (payload: any) => {
  const response = await api.post(
    `/document/${payload.id}`,
    {
      title: payload.title,
    },
    {
      headers: {
        authorization: payload.token,
      },
    },
  )
  return response.data
})

export const getSubCategoryById = createAsyncThunk(
  'category/getSubCategoryById',
  async (payload: any) => {
    const response = await api.get(`/category/sub-category/${payload.id}`, {
      headers: {
        authorization: payload.token,
      },
    })
    return response.data.categories
  },
)

export const deleteCategoryById = createAsyncThunk(
  'category/deleteCategoryById',
  async (payload: any) => {
    await api.delete(`/category/${payload.id}`, {
      headers: {
        authorization: payload.token,
      },
    })
    return { id: payload.id, statusType: payload.statusType }
  },
)
export const deleteDocumentById = createAsyncThunk(
  'category/deleteDocumentById',
  async (payload: any) => {
    await api.delete(`/document/${payload.id}`, {
      headers: {
        authorization: payload.token,
      },
    })
    return payload.id
  },
)
export const updateCategoryStatus = createAsyncThunk(
  'category/updateCategoryStatus',
  async (payload: any) => {
    const response = await api.put(
      `/category/${payload.id}`,
      { enabled: payload.enabled },
      {
        headers: {
          authorization: payload.token,
        },
      },
    )
    return response.data
  },
)

export const addSubCategory = createAsyncThunk('category/addSubCategory', async (payload: any) => {
  const response = await api.post('/category', payload.data, {
    headers: {
      authorization: payload.token,
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
})

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    resetById(state) {
      state.requestById.status = RequestStatus.idle
    },
    setModalId(state, action) {
      state.modalData = action.payload
    },
    getEnabledCategory(state) {
      state.enabledCategory.data = state.categories.filter((elm: ICategory) => elm.enabled === true)
      state.enabledCategory.length = state.enabledCategory.data.length
    },
    getDisabledCategory(state) {
      state.disabledCategory.data = state.categories.filter(
        (elm: ICategory) => elm.enabled === false,
      )
      state.disabledCategory.length = state.disabledCategory.data.length
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addCategory.pending, (state, action) => {
        state.create.status = RequestStatus.loading
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.create.status = RequestStatus.success
        state.categories = [action.payload, ...state.categories]
        // state.orders = action.payload
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.create.status = RequestStatus.error
        state.create.error = action.error.message
      })

    builder
      .addCase(getSubCategoryById.pending, (state, action) => {
        state.requestById.status = RequestStatus.loading
      })
      .addCase(getSubCategoryById.fulfilled, (state, action) => {
        state.requestById.status = RequestStatus.success
        state.requestById.data = action.payload
      })
      .addCase(getSubCategoryById.rejected, (state, action) => {
        state.requestById.status = RequestStatus.error
        state.requestById.error = action.error.message
      })
    builder
      // .addCase(get.pending, (state, action) => {
      //   state.create.status = RequestStatus.loading
      // })
      .addCase(addSubCategory.fulfilled, (state, action) => {
        state.create.status = RequestStatus.success
        // state.categories = [action.payload, ...state.categories]
        // state.orders = action.payload
      })
    builder
      .addCase(getAllCategory.pending, (state, action) => {
        state.status = RequestStatus.loading
      })
      .addCase(getAllCategory.fulfilled, (state, action) => {
        state.status = RequestStatus.success
        state.length = action.payload.length
        state.categories = action.payload
      })
      .addCase(getAllCategory.rejected, (state, action) => {
        state.status = RequestStatus.error
        state.error = action.error.message
      })

    builder
      .addCase(getCategoryById.pending, (state, action) => {
        state.editCategory.status = RequestStatus.loading
      })
      .addCase(getCategoryById.fulfilled, (state, action) => {
        state.editCategory.status = RequestStatus.success
        state.editCategory.data = action.payload
      })
      .addCase(getCategoryById.rejected, (state, action) => {
        state.editCategory.status = RequestStatus.error
        state.editCategory.error = action.error.message
      })

    builder
      .addCase(getDocuments.pending, (state, action) => {
        state.documents.status = RequestStatus.loading
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.documents.status = RequestStatus.success
        state.documents.data = action.payload
      })
      .addCase(getDocuments.rejected, (state, action) => {
        state.documents.status = RequestStatus.error
        state.documents.error = action.error.message
      })

    builder.addCase(createDocument.fulfilled, (state, action) => {
      state.documents.data.push(action.payload)
    })

    builder.addCase(deleteDocumentById.fulfilled, (state, action) => {
      state.documents.data = state.documents.data.filter((elm) => elm._id !== action.payload)
    })

    builder.addCase(deleteCategoryById.fulfilled, (state, action) => {
      if (action.payload.statusType === 'home') {
        state.categories = state.categories.filter((elm) => elm._id !== action.payload.id)
      } else {
        state.requestById.data = state.requestById.data.filter(
          (elm) => elm._id !== action.payload.id,
        )
      }
    })

    builder.addCase(updateCategoryStatus.fulfilled, (state, action) => {
      if (state.modalData!.statusType === 'sub') {
        state.requestById.data = state.requestById.data.map((elm) => {
          if (elm._id === action.payload._id) {
            elm.enabled = action.payload.enabled
          }
          return elm
        })
      } else {
        state.categories = state.categories.map((elm) => {
          if (elm._id === action.payload._id) {
            elm.enabled = action.payload.enabled
          }
          return elm
        })
      }
    })
  },
})

export default categorySlice.reducer
export const { resetById, setModalId, getDisabledCategory, getEnabledCategory } =
  categorySlice.actions
