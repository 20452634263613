
import { classNames } from '../../../../app/utils/helper'
import ErrorBtn from '../../../components/ErrorBtn'
import PrimaryBtn from '../../../components/PrimaryBtn'
import { ICustomer } from '../customer_list/customerSlice'

interface IProvidersListProps {
  customers: ICustomer[]
}
export default function CustomerRequestsTable({ customers }: IProvidersListProps) {
  let tmp = customers.map((customer, index) => (
    <tr
      key={index}
      className={classNames(index === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
    >
      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
        {index + 1}
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>{customer.phone}</td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>{customer.codeOtp}</td>
      {/* <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'> */}
      {/* <ToggleWithIcon /> */}
      {/* </td> */}
      {/* <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
        <div className='flex flex-row gap-5'>
          <PrimaryBtn text='edit' />
          <ErrorBtn text='delete' />
        </div>
      </td> */}
    </tr>
  ))
  return <>{tmp}</>
}
