import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProfile, selectAuth } from '../../pages/auth/authSlice'
import { AppDispatch } from '../store'
import { RequestStatus } from '../utils/interfaces'

const AuthContext = React.createContext<IAuthContext>({
  isAuthed: false,
  setAuthed: () => {},
  token: null,
})

interface IAuthContext {
  isAuthed: boolean
  setAuthed: React.Dispatch<React.SetStateAction<boolean>>
  token: string | null
}
export const useAuthContext = () => React.useContext(AuthContext)

export default function AuthProvider({ children }: any) {
  const token = localStorage.getItem('token')
  const [authed, setAuthed] = React.useState(Boolean(token))

  const authState = useSelector(selectAuth)
  const dispatch: AppDispatch = useDispatch()
  React.useEffect(() => {
    if (authState.status === RequestStatus.idle && Boolean(token)) {
      dispatch(getProfile(token))
    }
  }, [authState.status, dispatch])
  return (
    <AuthContext.Provider value={{ isAuthed: authed, setAuthed: setAuthed, token: token }}>
      {children}
    </AuthContext.Provider>
  )
}
