const checkboxList = [
  {
    name: 'Guests',
    type: 0,
  },
  {
    name: 'Customers',
    type: 1,
  },
  {
    name: 'Providers',
    type: 2,
  },
]
interface ISelectUsers {
  setCheckboxName: any
  checkboxName: any
}
export default function SelectUsers({ setCheckboxName, checkboxName }: ISelectUsers) {
  return (
    <fieldset className='space-y-2'>
      <label className='block text-sm font-medium text-gray-700'>Select Users</label>
      {checkboxList.map((elm) => {
        return (
          <div className='relative flex items-start'>
            <div className='flex h-5 items-center'>
              <input
                onChange={(e) =>
                  setCheckboxName({
                    ...checkboxName,
                    [e.target.name]: {
                      enabled: !checkboxName[e.target.name].enabled,
                      type: checkboxName[e.target.name].type,
                    },
                  })
                }
                name={elm.name}
                type='checkbox'
                className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
              />
            </div>
            <div className='ml-3 text-sm'>
              <label htmlFor='comments' className='font-medium text-gray-700'>
                {elm.name}
              </label>
            </div>
          </div>
        )
      })}
    </fieldset>
  )
}
