import { AddressCell } from './AddressCell'
import ProviderCell from './ProviderCell'
import StatusCell from './StatusCell'
import TextCell from './TextCell'

const SERVICES_COLUMNS = [
  {
    Header: 'Name',
    Cell: ({ ...props }) => <TextCell data={props.data[props.row.index]} />,
  },
  {
    Header: 'Provider',
    Cell: ({ ...props }) => <ProviderCell data={props.data[props.row.index]} />,
  },
  {
    Header: 'Address',
    Cell: ({ ...props }) => <AddressCell address={props.data[props.row.index]?.address} />,
  },
  {
    Header: 'status',
    Cell: ({ ...props }) => <StatusCell data={props.data[props.row.index]} />,
  },
]

export { SERVICES_COLUMNS }
