import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../../../../../api/axios_instance'
import { RequestStatus } from '../../../../../app/utils/interfaces'
import { IProvider } from '../../../provider_managment/provider_list/providerListSlice'
import { getSubCategoryById } from '../../category_setup/categorySlice'

interface IServiceListState {
  services: IService[]
  status: RequestStatus
  error?: string
  requestById: {
    data?: IService
    status: RequestStatus
    error?: string
  }
  modalData?: {
    id: string
    enabled: boolean
    statusType: 'list' | 'requests'
  }
  disabledServices: {
    data: IService[]
    error?: string
    length: number
  }
  subCategories: {
    data: any[]
    status: RequestStatus
    error?: string
  }
}
export interface IService {
  serviceId: string
  description: string
  quantity: number
  costPerHour: number
  parkingLocation: string
  isActive: boolean
  enabled: boolean
  provider: IProvider
  createdAt: string
  category: {
    title: string
    _id: string
    iconName: string
  }
  subCategory: {
    title: string
    _id: string
    iconName: string
  }
  document: IServiceDocument[]
  address: {
    city: string
    country: string
    district: string
  }
}

export interface IServiceDocument {
  filename: string
  path: string
  size: number
  title: string
  _id: string
}

const initialState: IServiceListState = {
  services: [],
  status: RequestStatus.idle,
  requestById: {
    status: RequestStatus.idle,
  },
  disabledServices: {
    data: [],
    length: 0,
  },
  subCategories: {
    data: [],
    status: RequestStatus.idle,
  },
}

export const getServices = createAsyncThunk('services/getServices', async (token: any) => {
  const response = await api.get('/services/', {
    headers: {
      authorization: token,
    },
  })
  return response.data.service
})

export const getServiceById = createAsyncThunk('services/getServiceById', async (payload: any) => {
  const response = await api.get(`/services/${payload.id}`, {
    headers: {
      authorization: payload.token,
    },
  })

  return response.data
})

export const deleteProviderById = createAsyncThunk(
  'services/deleteProviderById',
  async (payload: any) => {
    const response = await api.delete(`/provider/${payload.id}`, {
      headers: {
        authorization: payload.token,
      },
    })
    return { id: payload.id }
  },
)

export const updateServiceStatus = createAsyncThunk(
  'services/updateServiceStatus',
  async (payload: any) => {
    await api.put(
      `/services/${payload.id}`,
      { isActive: payload.enabled },
      {
        headers: {
          authorization: payload.token,
        },
      },
    )
    return payload.id
  },
)

export const deleteServiceRequest = createAsyncThunk(
  'services/deleteServiceRequest',
  async (payload: any) => {
    await api.delete(`/services/${payload.id}`, {
      headers: {
        authorization: payload.token,
      },
    })
    return payload.id
  },
)

export const serviceListSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    resetById(state) {
      state.requestById.status = RequestStatus.idle
    },
    setModalId(state, action) {
      state.modalData = action.payload
    },
    getDisabledServices(state) {
      state.disabledServices.data = state.services.filter((elm: IService) => elm.isActive === false)
      state.disabledServices.length = state.disabledServices.data.length
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getServices.pending, (state, action) => {
        state.status = RequestStatus.loading
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.status = RequestStatus.success
        state.services = action.payload
      })
      .addCase(getServices.rejected, (state, action) => {
        state.status = RequestStatus.error
        state.error = action.error.message
      })
    builder
      .addCase(getServiceById.pending, (state, action) => {
        state.requestById.status = RequestStatus.loading
      })
      .addCase(getServiceById.fulfilled, (state, action) => {
        state.requestById.status = RequestStatus.success
        state.requestById.data = action.payload
      })
      .addCase(getServiceById.rejected, (state, action) => {
        state.requestById.status = RequestStatus.error
        state.requestById.error = action.error.message
      })

    builder.addCase(updateServiceStatus.fulfilled, (state, action) => {
      if (state.modalData?.statusType === 'requests') {
        state.disabledServices.data = state.disabledServices.data.filter(
          (elm) => elm.serviceId !== action.payload,
        )
      } else {
        state.services = state.services.map((elm) => {
          if (elm.serviceId === action.payload) {
            elm.isActive = !elm.isActive
          }
          return elm
        })
      }
    })

    builder.addCase(deleteServiceRequest.fulfilled, (state, action) => {
      state.disabledServices.data = state.disabledServices.data.filter(
        (elm) => elm.serviceId !== action.payload,
      )
    })

    builder
      .addCase(getSubCategoryById.fulfilled, (state, action) => {
        const newData = action.payload.map((elm: any) => {
          return {
            label: elm.title,
            value: elm._id,
          }
        })
        state.subCategories.data = newData
        state.subCategories.status = RequestStatus.success
      })
      .addCase(getSubCategoryById.rejected, (state, action) => {
        state.subCategories.status = RequestStatus.error
        state.subCategories.error = action.error.message
      })
      .addCase(getSubCategoryById.pending, (state, action) => {
        state.subCategories.status = RequestStatus.loading
      })
    // builder.addCase(deleteProviderById.fulfilled, (state, action) => {
    //   state.services = state.services.filter((elm) => elm._id !== action.payload.id)
    // })
  },
})

export default serviceListSlice.reducer
export const { resetById, setModalId, getDisabledServices } = serviceListSlice.actions
