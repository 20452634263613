import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../../../api/axios_instance'
import { RequestStatus } from '../../../app/utils/interfaces'
import { IProvider } from '../provider_managment/provider_list/providerListSlice'
import { ICategory } from '../service_managment/category_setup/categorySlice'

interface IOrderState {
  status: RequestStatus
  error?: string
  orders: IOrder[]
  requestById: {
    data?: IOrder
    status: RequestStatus
    error?: string
  }
  summary: {
    data?: any
    status: RequestStatus
    error?: string
  }
}

export interface IOrder {
  _id: string
  orderKey: number
  fullName: string
  totalAmount: number
  createdAt: string
  phone: string
  quantity: number
  workTime: number
  statusOrder: number
  requestDate: string
  detailsOrder: string
  startLocation: string
  endLocation: string
  userId: string
  serviceId: string
  providerId: string
  users: IUser
  providers: IProvider
  category: ICategory
  subCategory: ICategory
}
export const orderStatus: { [key: string]: string } = {
  '1': 'Accepted',
  '2': 'Out For Delivery',
  '3': 'In Work',
  '4': 'Completed',
  '-1': 'Denied',
  '0': 'Pending',
}

interface IUser {
  _id: string
  fullName: string
  phone: string
  createdAt: string
  updatedAt: string
}
const initialState: IOrderState = {
  status: RequestStatus.idle,
  orders: [],
  requestById: {
    status: RequestStatus.idle,
  },
  summary: {
    status: RequestStatus.idle,
  },
}

export const getAllOrders = createAsyncThunk('orders/getAllOrders', async (token: any) => {
  const response = await api.get('/order', {
    headers: {
      authorization: token,
    },
  })
  return response.data.order
})

export const getOrderById = createAsyncThunk('orders/getOrderById', async (payload: any) => {
  const response = await api.get(`/order/${payload.id}`, {
    headers: {
      authorization: payload.token,
    },
  })
  return response.data
})

export const getHomeSummary = createAsyncThunk('orders/getHomeSummary', async (token: any) => {
  const response = await api.get('/report/summery', {
    headers: {
      authorization: token,
    },
  })
  return response.data
})

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetById(state) {
      state.requestById.status = RequestStatus.idle
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllOrders.pending, (state, action) => {
        state.status = RequestStatus.loading
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.status = RequestStatus.success
        state.orders = action.payload
      })
      .addCase(getAllOrders.rejected, (state, action) => {
        state.status = RequestStatus.error
        state.error = action.error.message
      })

    builder
      .addCase(getOrderById.pending, (state, action) => {
        state.requestById.status = RequestStatus.loading
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.requestById.status = RequestStatus.success
        state.requestById.data = action.payload
      })
      .addCase(getOrderById.rejected, (state, action) => {
        state.requestById.status = RequestStatus.error
        state.requestById.error = action.error.message
      })

    builder
      .addCase(getHomeSummary.pending, (state, action) => {
        state.summary.status = RequestStatus.loading
      })
      .addCase(getHomeSummary.fulfilled, (state, action) => {
        state.summary.status = RequestStatus.success
        state.summary.data = action.payload
      })
      .addCase(getHomeSummary.rejected, (state, action) => {
        state.summary.status = RequestStatus.error
        state.summary.error = action.error.message
      })
  },
})

export default ordersSlice.reducer
export const { resetById } = ordersSlice.actions
