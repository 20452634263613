import { useQuery } from 'react-query'
import { api } from '../../../../../../api/axios_instance'

const getDistrictByCityId = async (cityId: string) => {
  const response = await api.get(`/country/districts/${cityId}`)

  return response.data.districts
}

const useDistrictQuery = (cityId: string) => {
  return useQuery({
    queryKey: ['districts', cityId],
    queryFn: () => getDistrictByCityId(cityId),
    select(data) {
      const nextData = data.map((district: any) => ({ label: district.name, value: district.id }))
      return nextData
    },
  })
}

export { useDistrictQuery }
