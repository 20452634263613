const TextCell = ({ data }: any) => {
  return (
    <div className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
      <div className='flex'>
        <p className='truncate text-gray-500 group-hover:text-gray-900'>{data}</p>
      </div>
    </div>
  )
}

export default TextCell
