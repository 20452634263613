import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import SubOptions from './SubOptions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import { api } from '../../../../../api/axios_instance'
import { toast } from 'react-hot-toast'
import { useCitiesQuery } from './core/useCities'
import Select from 'react-select'
import { useDistrictQuery } from './core/useDistrict'
import { updateServiceAddress } from './core/useUpdateAddress'

export default function UpdateSerivceAddress({ open, setOpen, serviceId }: any) {
  const [selectedCityOption, setSelectedCityOption] = useState<any>({
    value: 1,
    label: 'امانه العاصمة',
  })
  const [selectedDistrictOption, setSelectedDistrictOption] = useState<any>(null)
  const citiesQuery = useCitiesQuery()
  const districtQuery = useDistrictQuery(selectedCityOption.value)

  if (citiesQuery.isLoading) {
    return <div>Loading...</div>
  }

  if (citiesQuery.isError) {
    const error = citiesQuery.error as Error
    return <div>{error.message}</div>
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                    <div className='px-4 sm:px-6'>
                      <div className='flex items-start justify-between'>
                        <Dialog.Title className='text-base font-semibold leading-6 text-gray-900'>
                          Update Sub Category
                        </Dialog.Title>
                        <div className='ml-3 flex h-7 items-center'>
                          <button
                            type='button'
                            className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                            onClick={() => setOpen(false)}
                          >
                            <span className='sr-only'>Close panel</span>
                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='relative mt-6 flex-1 px-4 sm:px-6'>
                      <h4 className='pb-1'>City</h4>
                      <Select
                        defaultValue={selectedCityOption}
                        onChange={(value) => {
                          setSelectedCityOption(value)
                          setSelectedDistrictOption(null)
                        }}
                        options={citiesQuery.data}
                      />
                      <div className='mt-4'></div>
                      <h4 className='pb-1'>District</h4>
                      <Select
                        onChange={(value) => setSelectedDistrictOption(value)}
                        isLoading={districtQuery.isLoading}
                        value={selectedDistrictOption}
                        options={districtQuery.data}
                      />
                      <button
                        type='button'
                        className='mt-3 rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:opacity-50'
                        disabled={districtQuery.isLoading || !selectedDistrictOption}
                        onClick={async () => {
                          // await api.put(
                          //   '/services/update-subCategory/' +
                          //     serviceState.requestById.data?.serviceId,
                          //   {
                          //     subCategoryId: selectedOption?.value,
                          //   },
                          // )
                          // setOpen(false)
                          const address = {
                            city: selectedCityOption.value,
                            district: selectedDistrictOption.value,
                          }
                          await updateServiceAddress(serviceId, address)
                          setOpen(false)
                          window.location.reload()
                        }}
                      >
                        update
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
