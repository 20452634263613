import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '../../../api/axios_instance'
import { RequestStatus } from '../../../app/utils/interfaces'

interface ISystemSlice {
  status: RequestStatus
  error?: string
  appSettings: {
    data?: IAppSettings
    status: RequestStatus
    error?: string
  }
  policy: {
    data?: IPolicy
    status: RequestStatus
    error?: string
  }
  updateType?: string
}

interface IPolicy {
  _id: string
  policy: string
  deleted: boolean
  createdAt: string
}

interface IAppSettings {
  _id: string
  forceUpdate: boolean
  maintenance: boolean
  optionalUpdate: boolean
  ios: number
  android: number
  deleted: boolean
  createdAt: string
}
const initialState: ISystemSlice = {
  status: RequestStatus.idle,
  appSettings: { status: RequestStatus.idle },
  policy: {
    status: RequestStatus.idle,
  },
}
export const getPolicy = createAsyncThunk('system/getPolicy', async (token: any) => {
  const response = await api.get('/setting/policy', {
    headers: {
      authorization: token,
    },
  })
  return response.data
})

export const updatePolicy = createAsyncThunk('system/updatePolicy', async (payload: any) => {
  const response = await api.put(
    '/setting/policy',
    {
      policy: payload.policy,
    },
    {
      headers: {
        authorization: payload.token,
      },
    },
  )
  return response.data
})

export const getSettings = createAsyncThunk('system/getSettings', async (token: any) => {
  const response = await api.get('/setting/app', {
    headers: {
      authorization: token,
    },
  })
  return response.data
})

export const updateSettings = createAsyncThunk('system/updateSettings', async (payload: any) => {
  const response = await api.put('/setting/app', payload.appSetting, {
    headers: {
      authorization: payload.token,
    },
  })
  return response.data
})

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    addVersionType(state, action) {
      state.updateType = action.payload
    },
    updateMaintainceMode(state, action) {
      state.appSettings.data!.maintenance = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPolicy.pending, (state, action) => {
        state.policy.status = RequestStatus.loading
      })
      .addCase(getPolicy.fulfilled, (state, action) => {
        state.policy.status = RequestStatus.success
        state.policy.data = action.payload
      })
      .addCase(getPolicy.rejected, (state, action) => {
        state.policy.status = RequestStatus.error
      })

    builder
      // .addCase(updatePolicy.pending, (state, action) => {
      //   state.policy.status = RequestStatus.loading
      // })
      .addCase(updatePolicy.fulfilled, (state, action) => {
        state.policy.status = RequestStatus.success
      })
    // .addCase(updatePolicy.rejected, (state, action) => {
    //   state.policy.status = RequestStatus.error
    // })
    builder
      .addCase(getSettings.pending, (state, action) => {
        state.appSettings.status = RequestStatus.loading
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.appSettings.status = RequestStatus.success
        state.appSettings.data = action.payload
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.appSettings.status = RequestStatus.error
      })

    builder
      // .addCase(updatePolicy.pending, (state, action) => {
      //   state.policy.status = RequestStatus.loading
      // })
      .addCase(updateSettings.fulfilled, (state, action) => {
        state.appSettings.status = RequestStatus.success
      })
    // .addCase(updatePolicy.rejected, (state, action) => {
    //   state.policy.status = RequestStatus.error
    // })
  },
})

export default systemSlice.reducer
export const { addVersionType, updateMaintainceMode } = systemSlice.actions
//
