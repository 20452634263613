import DateCell from './DateCell'
import StatusCell from './StatusCell'
import TextCell from './TextCell'

const ORDER_COLUMNS = [
  {
    Header: 'Order',
    Cell: ({ ...props }) => <TextCell data={props.data[props.row.index]} />,
  },
  {
    Header: 'Status',
    Cell: ({ ...props }) => <StatusCell statusOrder={props.data[props.row.index].statusOrder} />,
  },
  {
    Header: 'Date',
    Cell: ({ ...props }) => <DateCell date={props.data[props.row.index].createdAt} />,
  },
]

export { ORDER_COLUMNS }
