import DateCell from '../../../../home/activity-table/columns/DateCell'
import StatusCell from '../../../../home/activity-table/columns/StatusCell'
import TextCell from './TextCell'

const CUSTOMERS_COLUMNS = [
  {
    Header: 'fullName',
    Cell: ({ ...props }) => <TextCell data={props.data[props.row.index]?.fullName} />,
  },
  {
    Header: 'phone',
    Cell: ({ ...props }) => <TextCell data={props.data[props.row.index]?.phone} />,
  },
  // {
  //   Header: 'Date',
  //   Cell: ({ ...props }) => <DateCell date={props.data[props.row.index].createdAt} />,
  // },
]

export { CUSTOMERS_COLUMNS }
