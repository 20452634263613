interface IErrorBtn {
  text: string
  onClick?: any
}
export default function ErrorBtn({ text, onClick }: IErrorBtn) {
  return (
    <button onClick={onClick} className='rounded-md bg-red-50 px-4 py-2 capitalize text-red-600 shadow-sm hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2'>
      {text}
    </button>
  )
}
