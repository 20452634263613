import { useQuery } from 'react-query'
import { api } from '../../../../../../api/axios_instance'

const getAllCities = async () => {
  const response = await api.get('/country/cites')

  return response.data.cities
}

const useCitiesQuery = () => {
  return useQuery({
    queryKey: 'cities',
    queryFn: getAllCities,
    select(data) {
      const nextData = data.map((city: any) => ({ label: city.name, value: city.id }))
      return nextData
    },
  })
}

export { useCitiesQuery }
