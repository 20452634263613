import { Link } from 'react-router-dom'

const ProviderCell = ({ data }: any) => {
  return (
    <div className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
      <div className='flex'>
        <a href={data?.serviceId} className='group inline-flex space-x-2 truncate text-sm'>
          <p className='w-60 truncate '>{data?.provider?.fullName}</p>
        </a>
      </div>
    </div>
  )
}

export default ProviderCell
