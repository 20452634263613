import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SpinnerRoundFilled } from 'spinners-react'
import { AppDispatch, RootState } from '../../../app/store'
import { RequestStatus } from '../../../app/utils/interfaces'
import { selectAuth } from '../../auth/authSlice'
import CustomNotification from '../../components/notification'
import { getPolicy, updatePolicy } from './systemSlice'

export default function TermsAndConditions() {
  const dispatch: AppDispatch = useDispatch()
  const authState = useSelector(selectAuth)
  const systemState = useSelector((state: RootState) => state.system)
  const [policy, setPolicy] = useState(systemState.policy.data?.policy)
  const [notify, setNotify] = useState(false)
  useEffect(() => {
    if (authState.status == RequestStatus.success) {
      dispatch(getPolicy(authState.user.token))
    }
  }, [authState.status])
  let content
  if (systemState.policy.status === RequestStatus.loading) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  } else if (systemState.policy.status === RequestStatus.success) {
    content = (
      <>
        <div className=' mx-auto max-w-6xl space-y-6 px-4 pt-8'>
          <div className='bg-white px-4 py-5 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg sm:p-6'>
            <CustomNotification
              message='updated policy successfully'
              show={notify}
              setShow={setNotify}
            />
            <h3 className='mb-4 text-xl font-medium leading-6 text-gray-900'>
              Terms and Conditions
            </h3>
            <textarea
              id='about'
              dir='rtl'
              name='about'
              rows={15}
              defaultValue={systemState.policy.data?.policy}
              value={policy}
              onChange={(e) => setPolicy(e.target.value)}
              className='block w-full  rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
            />
            <div className='mt-2'>
              <button
                type='button'
                onClick={(e) => {
                  dispatch(updatePolicy({ token: authState.user.token, policy }))
                  setNotify(true)
                }}
                className='inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
  return <div className='bg-gray-50'>{content}</div>
}
