
import { Fragment, useState } from 'react'
import { CategoryListTableHeader, DocumentListTableHeader, providerListTabs } from '../../../../app/utils/data-mock'
import CustomTable from '../../../components/CustomTable'
import Header from '../../../components/Header'
import Tabs from '../../../components/Tabs'
import CategoryListTableBody from './CategoryListTableBody'
import { ICategory, ICategoryDocument } from './categorySlice'
import DocumentsListTable from './DocumentsListTable'

interface IDocumentsList {
  documents: ICategoryDocument[]
  onStatusClick: any
}
export default function DocumentsList({ documents, onStatusClick }: IDocumentsList) {
  const [tab, setTab] = useState('All')
  return (
    <div className='mx-auto  max-w-6xl'>
      <div className='flex flex-col pt-4'>
        {/* <Tabs currentTab={tab} setTab={setTab} tabs={providerListTabs} /> */}
        <CustomTable tableHeaders={DocumentListTableHeader}>
            <DocumentsListTable documents={documents} onStatusClick={onStatusClick} />
        </CustomTable>
      </div>
    </div>
  )
}
