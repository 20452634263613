import { useCallback, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { SpinnerRoundFilled } from 'spinners-react'
import { AppDispatch, RootState } from '../../../../app/store'
import { RequestStatus } from '../../../../app/utils/interfaces'
import { selectAuth } from '../../../auth/authSlice'
import ImageUploader from '../../../components/ImageUploader'
import Modal from '../../../components/Modal'
import CustomNotification from '../../../components/notification'
import {
  addCategory,
  deleteCategoryById,
  getAllCategory,
  getDisabledCategory,
  getEnabledCategory,
  setModalId,
  updateCategoryStatus,
} from './categorySlice'
import CategoryList from './CategoryTable'
import PriorityInput from './Priority'
interface IFormInput {
  CategoryName: string
  attachments: string
}
export default function CategorySetup() {
  const [show, setShow] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>()
  const [sortOrder, setSortOrder] = useState(0)
  const [image, setImage]: any = useState()
  const dispatch: AppDispatch = useDispatch()
  const authState = useSelector(selectAuth)
  const categoryState = useSelector((state: RootState) => state.category)
  const [open, setOpen] = useState(false)
  const [openNotify, setOpenNotify] = useState(false)
  const [tab, setTab] = useState(categoryState.tab)
  const onSubmit = (data: IFormInput) => {
    const form = new FormData()
    if (data) {
      form.append('title', data.CategoryName)
      form.append('iconName', image.path)
      form.append('image', image)
      form.append('sortOrder', sortOrder.toString())
      dispatch(addCategory({ token: authState.user.token, data: form }))
      reset({ CategoryName: '', attachments: '' })
      setShow(true)

    }
  }
  const onDrop = useCallback((acceptedFiles: any) => {
    setImage(acceptedFiles[0])
  }, [])

  useEffect(() => {
    if (authState.status == RequestStatus.success) {
      dispatch(getAllCategory(authState.user.token))
    }
  }, [authState.status ])

  const onDelete = (id: string) => {
    // dispatch(deleteProviderById({ id: id, token: authState.user.token }))
  }
  let content
  if (categoryState.status === RequestStatus.loading) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  } else if (categoryState.status === RequestStatus.success) {
    content = (
      <div>
        <Modal
          open={open}
          setOpen={setOpen}
          title='Operation confirmation'
          desc='By changing status this will affect the user app'
          onConfirm={async () => {
            dispatch(
              updateCategoryStatus({
                token: authState.user.token,
                enabled: !categoryState.modalData?.enabled,
                id: categoryState.modalData?.id,
              }),
            )
            setTimeout(() => {
              setOpenNotify(true)
            }, 1000)
          }}
        />
        <CustomNotification
          message='updated category successfully'
          show={openNotify}
          setShow={setOpenNotify}
        />
        <CategoryList
          categories={categoryState.categories}
          onStatusClick={onStatusClick}
          onDeleteClick={onDeleteClick}
        />
      </div>
    )
  } else if (categoryState.status === RequestStatus.error) {
    content = <div>{categoryState.error}</div>
  }
  return (
    <div className='bg-gray-50'>
      {/* <CustomNotification show={show} setShow={setShow} /> */}
      {categoryState.create.status === RequestStatus.success && (
        <CustomNotification message='Category created successfully' show={show} setShow={setShow} />
      )}
      <div className='mx-auto max-w-6xl space-y-6 px-4 pt-8'>
        <div className='bg-white px-4 py-5 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg sm:p-6'>
          <h3 className='text-xl font-medium leading-6 text-gray-900'>Category Setup</h3>
          <div className='mt-6 flex flex-row gap-10'>
            <div className='w-1/3 space-y-4'>
              <div className=''>
                <label
                  className={`block text-sm font-medium text-gray-700 ${
                    !errors.CategoryName?.message ? 'text-gray-700' : 'text-red-600'
                  }`}
                >
                  Category Name {errors.CategoryName?.message}
                </label>
                <input
                  type='text'
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                    !errors.CategoryName?.message
                      ? 'border focus:border-indigo-500 focus:ring-indigo-500'
                      : 'border-red-500 focus:border-red-500 focus:ring-red-500 '
                  }`}
                  {...register('CategoryName', {
                    required: {
                      value: true,
                      message: 'is required',
                    },
                  })}
                />
              </div>
              <PriorityInput setSortOrder={setSortOrder} />
            </div>
            <div className='flex w-2/3 flex-col justify-between space-y-4 '>
              <div className=''>
                <div>
                  <label
                    className={`block text-sm font-medium text-gray-700 ${
                      !errors.attachments?.message ? 'text-gray-700' : 'text-red-600'
                    }`}
                  >
                    Logo Image {errors.attachments?.message}
                  </label>
                  <div
                    className={`mt-1 rounded-md border-2 border-dashed   ${
                      !errors.attachments?.message ? 'border-gray-300' : 'border-red-500  '
                    }`}
                  >
                    <ImageUploader onDrop={onDrop} register={register} />
                  </div>
                </div>
              </div>
              <div className='  text-right '>
                <button
                  type='button'
                  onClick={handleSubmit(onSubmit)}
                  className='inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {content}
    </div>
  )
  async function onStatusClick(id: string, enabled: boolean) {
    await dispatch(setModalId({ id: id, enabled: enabled, statusType: 'home' }))
    setOpen(true)
  }
  async function onDeleteClick(id: string) {
    await dispatch(deleteCategoryById({ id: id, token: authState.user.token, statusType: 'home' }))
  }
}
