import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '../../../../api/axios_instance'
import { RequestStatus } from '../../../../app/utils/interfaces'

interface ICustomerListState {
  customers: ICustomer[]
  status: RequestStatus
  error?: string
  requestById: {
    data?: ICustomer
    status: RequestStatus
    error?: string
  }
}
export interface ICustomer {
  _id: string
  fullName: string
  phone: string
  createdAt?: string
  codeOtp: number 
}

// export const selectAuth = (state: RootState) => state.auth

const initialState: ICustomerListState = {
  customers: [],
  status: RequestStatus.idle,
  requestById: {
    status: RequestStatus.idle,
  },
}

export const getCustomers = createAsyncThunk('customers/getCustomers', async (token: any) => {
  const response = await api.get('/user/', {
    headers: {
      authorization: token,
    },
  })
  return response.data.user
})

export const getCustomerById = createAsyncThunk(
  'customers/getCustomerById',
  async (payload: any) => {
    const response = await api.get(`/user/profile/${payload.id}`, {
      headers: {
        authorization: payload.token,
      },
    })

    return response.data
  },
)

// export const deleteProviderById = createAsyncThunk(
//   'providers/deleteProviderById',
//   async (payload: any) => {
//     const response = await api.delete(`/provider/${payload.id}`, {
//       headers: {
//         authorization: payload.token,
//       },
//     })
//     return { id: payload.id }
//   },
// )

export const customerListSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    resetById(state) {
      state.requestById.status = RequestStatus.idle
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCustomers.pending, (state, action) => {
        state.status = RequestStatus.loading
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.status = RequestStatus.success
        state.customers = action.payload
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.status = RequestStatus.error
        state.error = action.error.message
      })

    builder
      .addCase(getCustomerById.pending, (state, action) => {
        state.requestById.status = RequestStatus.loading
      })
      .addCase(getCustomerById.fulfilled, (state, action) => {
        state.requestById.status = RequestStatus.success
        state.requestById.data = action.payload
      })
      .addCase(getCustomerById.rejected, (state, action) => {
        state.requestById.status = RequestStatus.error
        state.requestById.error = action.error.message
      })

    // builder.addCase(deleteProviderById.fulfilled, (state, action) => {
    //   state.providers = state.providers.filter((elm) => elm._id !== action.payload.id)
    // })
  },
})

export default customerListSlice.reducer
export const { resetById } = customerListSlice.actions
