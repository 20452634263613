import { BanknotesIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

const TextCell = ({ data }: any) => {
  return (
    <div className='whitespace-nowrap px-6 py-4 text-sm text-gray-900'>
      <div className='flex'>
        <a href={data?.serviceId} className='group space-x-2 truncate text-sm'>
          <Link to={`/services/${data?.serviceId}`}>
            <p
              className='w-60 truncate text-start text-gray-500 group-hover:text-gray-900'
              dir='rtl'
            >
              {data.description}
            </p>
          </Link>
        </a>
      </div>
    </div>
  )
}

export default TextCell
