import { formatRelative } from 'date-fns'

const DateCell = ({ date }: any) => {
  return (
    <td className='whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500'>
      <time>{formatRelative(new Date(date), new Date())}</time>
    </td>
  )
}

export default DateCell
