import { statusStyles } from '../../../../../app/utils/data-mock'
import { classNames } from '../../../../../app/utils/helper'
import { orderStatus } from '../../../orders/orderSlice'

const StatusCell = ({ statusOrder }: { statusOrder: number }) => {
  return (
    <td className='hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block'>
      <span
        className={classNames(
          statusStyles[orderStatus[statusOrder.toString()]],
          'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize',
        )}
      >
        {orderStatus[statusOrder.toString()]}
      </span>
    </td>
  )
}

export default StatusCell
