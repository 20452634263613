import { ArrowLeftCircleIcon, DevicePhoneMobileIcon, UserIcon } from '@heroicons/react/24/outline'
import { formatRelative } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { SpinnerRoundFilled } from 'spinners-react'
import { AppDispatch, RootState } from '../../../../../app/store'
import { RequestStatus } from '../../../../../app/utils/interfaces'
import { selectAuth } from '../../../../auth/authSlice'
import { IProvider } from '../../../provider_managment/provider_list/providerListSlice'
import { getSubCategoryById } from '../../category_setup/categorySlice'
import { getServiceById, IService, IServiceDocument } from './serviceSlice'
import UpdateSubCategory from './UpdateSubCategory'
import UpdateImage from './UpdateImage'
import UpdateSerivceAddress from './UpdateServiceAddress'

const imgUrl = 'https://api.mueadaat.com/'

export default function ServiceDetails() {
  const params = useParams()
  const [showCategoryCanvas, setShowCategoryCanvas] = useState(false)
  const [showImageCanvas, setShowImageCanvas] = useState(false)
  const [showAddressCanvas, setShowAddressCanvas] = useState(false)

  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const serviceState = useSelector((state: RootState) => state.services)
  const authState = useSelector(selectAuth)

  function getSub(id: string) {
    dispatch(getSubCategoryById({ token: authState.user.token, id }))
  }

  function handleBackButton() {
    navigate(-1)
    // dispatch(resetUserById())
  }
  useEffect(() => {
    if (authState.status == RequestStatus.success) {
      dispatch(getServiceById({ token: authState.user.token, id: params.id }))
    }
  }, [authState.status])

  let content
  if (serviceState.requestById.status === RequestStatus.loading) {
    return (
      <div className='ml-8 flex  h-screen  items-center justify-center overflow-hidden bg-gray-100 opacity-75'>
        <span className='visually-hidden'>
          <SpinnerRoundFilled size={100} />
        </span>
      </div>
    )
  } else if (serviceState.requestById.status === RequestStatus.success) {
    const { costPerHour, quantity, description, parkingLocation, createdAt, document }: IService =
      serviceState.requestById.data!
    const { fullName, phone }: IProvider = serviceState.requestById.data?.provider!
    const category: any = serviceState.requestById.data?.category.title
    const subCategory: any = serviceState.requestById.data?.subCategory.title
    const address = serviceState.requestById.data?.address
    const serviceId = serviceState.requestById.data?.serviceId
    // content = <ProviderListTableBody people={providersState.providers} />

    content = (
      <div className='mx-auto max-w-6xl space-y-6 px-4 pt-8 '>
        <div className='overflow-hidden bg-white shadow sm:rounded-lg'>
          <div className='flex items-start gap-5 px-4 py-5 sm:px-6'>
            <span>
              <ArrowLeftCircleIcon
                className='h-8 w-8 text-gray-500 hover:text-gray-600'
                onClick={handleBackButton}
              />
            </span>
            <div>
              <h3 className=' text-lg font-medium leading-6 text-gray-900'>Service Details</h3>
              <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                {formatRelative(new Date(createdAt), new Date())}
              </p>
            </div>
            <div className='flex-grow'></div>
            <button
              className='rounded-md bg-indigo-600 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              onClick={() => {
                setShowAddressCanvas(true)
              }}
            >
              update service address
            </button>
            <button
              className='rounded-md bg-indigo-600 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              onClick={() => {
                setShowCategoryCanvas(true)
                getSub(serviceState.requestById.data?.category._id!)
              }}
            >
              update sub category
            </button>
            <button
              className='rounded-md bg-indigo-600 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              onClick={() => {
                setShowImageCanvas(true)
                // getSub(serviceState.requestById.data?.category._id!)
              }}
            >
              update image
            </button>
          </div>
          <UpdateSubCategory open={showCategoryCanvas} setOpen={setShowCategoryCanvas} />
          <UpdateImage open={showImageCanvas} setOpen={setShowImageCanvas} />
          <UpdateSerivceAddress
            open={showAddressCanvas}
            setOpen={setShowAddressCanvas}
            serviceId={serviceId}
          />
          <ServiceInformation
            costPerHour={costPerHour}
            quantity={quantity}
            description={description}
            parkingLocation={parkingLocation}
            category={category}
            subCategory={subCategory}
            address={address}
          />
          <ProviderInformation username={fullName} phone={phone} />
          <ServiceImages documents={document} />
          {/* <CategoryInformation category={category} subCategory={subCategory} /> */}
          {/* <ProviderImages documents={serviceState.requestById.data?.dataRequest.providerDocument} /> */}
        </div>
      </div>
    )
  } else if (serviceState.requestById.status === RequestStatus.error) {
    content = <div>{serviceState.requestById.error}</div>
  }
  return <div className='h-screen bg-gray-100'>{content}</div>
}

function ServiceInformation({
  description,
  quantity,
  costPerHour,
  parkingLocation,
  category,
  subCategory,
  address,
}: {
  description: string
  quantity: number
  costPerHour: number
  parkingLocation: string
  category: string
  subCategory: string
  address:
    | {
        city: string
        district: string
        country: string
      }
    | undefined
}) {
  const nextAddress = !address ? 'no address' : `${address.city}, ${address.district}`

  return (
    <div className='border-t border-gray-200 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
      <dt className='text-sm font-medium text-gray-500'> Service Information</dt>
      <ul role='list' className='divide-y divide-gray-200 rounded-md border border-gray-200'>
        <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
          <div className='flex w-0 flex-1 items-center'>
            {/* <UserIcon className='h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' /> */}
            Description: <span className='ml-2 w-0 flex-1 truncate'>{description}</span>
          </div>
        </li>
        <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
          <div className='flex w-0 flex-1 items-center'>
            {/* <DevicePhoneMobileIcon
              className='h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            /> */}
            Quantity: <span className='ml-2 w-0 flex-1 truncate'>{quantity}</span>
          </div>
        </li>
        <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
          <div className='flex w-0 flex-1 items-center'>
            {/* <DevicePhoneMobileIcon
              className='h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            /> */}
            Parking Location: <span className='ml-2 w-0 flex-1 truncate'>{parkingLocation}</span>
          </div>
        </li>
        <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
          <div className='flex w-0 flex-1 items-center'>
            {/* <DevicePhoneMobileIcon
              className='h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            /> */}
            Cost Per Hour: <span className='ml-2 w-0 flex-1 truncate'>{costPerHour}</span>
          </div>
        </li>
        <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
          <div className='flex w-0 flex-1 items-center'>
            {/* <DevicePhoneMobileIcon
              className='h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            /> */}
            Address: <span className='ml-2 w-0 flex-1 truncate'>{nextAddress}</span>
          </div>
        </li>
        <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
          <div className='flex w-0 flex-1 items-center'>
            {/* <DevicePhoneMobileIcon
              className='h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            /> */}
            Category: <span className='ml-2 w-0 flex-1 truncate'>{category}</span>
          </div>
        </li>
        <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
          <div className='flex w-0 flex-1 items-center'>
            {/* <DevicePhoneMobileIcon
              className='h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            /> */}
            Sub Category: <span className='ml-2 w-0 flex-1 truncate'>{subCategory}</span>
          </div>
        </li>
      </ul>
    </div>
  )
}
function ProviderInformation({ username, phone }: { username: string; phone: string }) {
  return (
    <div className='border-t border-gray-200 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
      <dt className='text-sm font-medium text-gray-500'> Provider Information</dt>
      <ul role='list' className='divide-y divide-gray-200 rounded-md border border-gray-200'>
        <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
          <div className='flex w-0 flex-1 items-center'>
            <UserIcon className='h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
            <span className='ml-2 w-0 flex-1 truncate'>{username}</span>
          </div>
        </li>
        <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
          <div className='flex w-0 flex-1 items-center'>
            <DevicePhoneMobileIcon
              className='h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            />
            <span className='ml-2 w-0 flex-1 truncate'>{phone}</span>
          </div>
        </li>
      </ul>
    </div>
  )
}

function ServiceImages({ documents }: { documents?: IServiceDocument[] }) {
  return (
    <div className='border-t border-gray-200 px-4 py-5 sm:p-0'>
      <dl className='sm:divide-y sm:divide-gray-200'>
        <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>Provider Documents</dt>
          <div className='flex gap-3'>
            {documents?.map((doc, index) => (
              <div className='w-40 rounded-md border border-gray-100 p-2'>
                <p dir='rtl' className='mb-3'>
                  {doc.title}
                </p>
                <div className='' key={index}>
                  <img src={`${imgUrl}${doc.path}`} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </dl>
    </div>
  )
}
